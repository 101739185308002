import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunkMiddleware from "redux-thunk";
import reducers from "./rootReducers";

const middleware = [thunkMiddleware];

const store = createStore(
  reducers,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;

// import { configureStore } from "@reduxjs/toolkit";
// // import authReducer from "./slices/authSlice"; // Ensure the correct path to `authSlice.js`
// import authReducer from "./authSlice/authSlice";

// const store = configureStore({
//   reducer: {
//     auth: authReducer, // Add the auth slice to manage authentication state
//   },
// });

// export default store;
