import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Accordion } from "react-bootstrap";
import { getPosInfo } from "../store/actions/posInfo";
import { toast } from "react-toastify";
import Header from "../components/Header";
import PersonalDetailsForm from "../components/PersonalDetailsForm";
import ContactDetailsForm from "../components/ContactDetailsForm";
import EducationDetailsFrom from "../components/EducationDetailsFrom";
import BankDetailsForm from "../components/BankDetailsForm";
import BranchForm from "../components/BranchForm";
import POSStatus from "../components/POSStatus";
import AgreementForm from "../components/AgreementForm";
import Loading from "../components/Loading";
import {
  updateBankCopy,
  uploadProfile,
  updateAdhaarImagesFront,
  updateAdhaarImagesBack,
  updatePan,
  updateEducationCert,
} from "../store/actions/Image";
import { useNavigate } from "react-router-dom";
import {
  updateBankDetails,
  updatePersonalDetails,
  updateEducationDetails,
} from "../store/actions/personalDetails";
import { checkImgValidation } from "../utils/methods";
const ViewPOS = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const result = useSelector((state) => state.posInfo.data.data);
  const [show, setShow] = useState(false);
  const [edit, editDetails] = useState(true);
  const [bankLoad, setbankLoad] = useState(false);
  const [chequeFile, setChequeFile] = useState([]);

  const [photoLoad, setphotoLoad] = useState(false);
  const [photoFile, setPhotoFile] = useState([]);

  const [AadhaarLoad, setaadhaarLoad] = useState(false);
  const [aadharFile, setAadharFile] = useState([]);

  const [AadhaarLoadBack, setaadhaarLoadBack] = useState(false);
  const [aadharFileBack, setAadharFileBack] = useState([]);

  const [panLoad, setpanLoad] = useState(false);
  const [cnicFile, setCnicFile] = useState([]);

  const [eduLoad, seteduLoad] = useState(false);
  const [offersFile, setOffersFile] = useState([]);

  const [personalDetail, setPersonalDetail] = useState({
    assignedBranch: "",
    branchManager: "",
  });
  const navigate = useNavigate();
  const uploadSingleFile = (e, field) => {
    if (checkImgValidation(e?.target?.files?.[0]) === false) {
      return;
    }
    if (field === "Cheque") {
      setbankLoad(true);
      const formData = new FormData();
      formData.append("bankCopy", e.target.files[0]);
      updateBankCopy(formData)
        .then((res) => {
          if (res.status === 0) {
            setbankLoad(false);
          } else {
            setbankLoad(false);
            setChequeFile([...chequeFile, res.bankCopyImageUrl]);
            setPersonalDetail({
              ...personalDetail,
              ["bankCopyImageUrl"]: res.bankCopyImageUrl,
            });
          }
        })
        .catch((error) => {
          setbankLoad(false);
        });
    }

    if (field === "Photo") {
      setphotoLoad(true);
      const formData = new FormData();
      formData.append("photo", e.target.files[0]);
      uploadProfile(formData)
        .then((res) => {
          if (res.status === 0) {
            setphotoLoad(false);
          } else {
            setphotoLoad(false);
            setPhotoFile([...photoFile, res.photoUrl]);
            setPersonalDetail({
              ...personalDetail,
              ["photoUrl"]: res.photoUrl,
            });
          }
        })
        .catch((error) => {
          setphotoLoad(false);
        });
    }

    if (field === "Aadhaar") {
      setaadhaarLoad(true);
      const formData = new FormData();
      formData.append("aadhaarImageFront", e.target.files[0]);
      updateAdhaarImagesFront(formData)
        .then((res) => {
          if (res.status === 0) {
            setaadhaarLoad(false);
          } else {
            setaadhaarLoad(false);
            setAadharFile([...aadharFile, res.aadhaarImageFront]);
            setPersonalDetail({
              ...personalDetail,
              ["aadhaarImageFront"]: res.aadhaarImageFront,
            });
          }
        })
        .catch((error) => {
          setaadhaarLoad(false);
        });
    }

    if (field === "AadhaarBack") {
      setaadhaarLoadBack(true);
      const formData = new FormData();
      formData.append("aadhaarImageBack", e.target.files[0]);
      updateAdhaarImagesBack(formData)
        .then((res) => {
          if (res.status === 0) {
            setaadhaarLoadBack(false);
          } else {
            setaadhaarLoadBack(false);
            setAadharFileBack([...aadharFileBack, res.aadhaarImageBack]);
            setPersonalDetail({
              ...personalDetail,
              ["aadhaarImageBack"]: res.aadhaarImageBack,
            });
          }
        })
        .catch((error) => {
          setaadhaarLoadBack(false);
        });
    }

    if (field === "CNIC") {
      setpanLoad(true);
      const formData = new FormData();
      formData.append("pancard", e.target.files[0]);
      updatePan(formData)
        .then((res) => {
          if (res.status === 0) {
            setpanLoad(false);
          } else {
            setpanLoad(false);
            setCnicFile([...cnicFile, res.pancardImageUrl]);
            setPersonalDetail({
              ...personalDetail,
              ["pancardImageUrl"]: res.pancardImageUrl,
            });
          }
        })
        .catch((error) => {
          setpanLoad(false);
        });
    }

    if (field === "Offers") {
      seteduLoad(true);
      const formData = new FormData();
      formData.append("EduCert", e.target.files[0]);
      updateEducationCert(formData)
        .then((res) => {
          if (res.status === 0) {
            seteduLoad(false);
          } else {
            seteduLoad(false);
            setOffersFile([...offersFile, res.eduProofImageUrl1]);
            setPersonalDetail({
              ...personalDetail,
              ["eduProofImageUrl1"]: res.eduProofImageUrl1,
            });
            //setOffersFile([...offersFile, res.eduProofImageUrl2]);
            //setPersonalDetail({ ...personalDetail, ["eduProofImageUrl2"]: res.eduProofImageUrl2 });
          }
        })
        .catch((error) => {
          seteduLoad(false);
        });
    }
  };
  const personalchk = (sectionname, e) => {
    //if (sectionname === "assignedBranch") {
    setPersonalDetail({ ...personalDetail, [sectionname]: e.target.value });
    //}
    // if (sectionname === "branchManager") {
    //   setPersonalDetail({ ...personalDetail, [sectionname]: e.target.value });
    // }
  };
  const updateBDetails = () => {
    updateBankDetails(personalDetail)
      .then((res) => {
        res?.status === 0 && toast.error(res?.message);
        if (res?.data?.errors) {
          const data = res.data.errors;
          data.forEach((element) => {
            toast.error(element.msg);
          });
        }
        if (res?.status === 1) {
          toast.success("Pos updated successfully.");
          navigate("/pos/all");
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const updatePDetails = () => {
    updatePersonalDetails(personalDetail)
      .then((res) => {
        res?.status === 0 && toast.error(res?.message);
        if (res?.data?.errors) {
          const data = res.data.errors;
          data.forEach((element) => {
            toast.error(element.msg);
          });
        }
        if (res?.status === 1) {
          toast.success("Pos updated successfully.");
          navigate("/pos/all");
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const updateEDetails = () => {
    updateEducationDetails(personalDetail)
      .then((res) => {
        res?.status === 0 && toast.error(res?.message);
        if (res?.data?.errors) {
          const data = res.data.errors;
          data.forEach((element) => {
            toast.error(element.msg);
          });
        }
        if (res?.status === 1) {
          toast.success("Pos updated successfully.");
          navigate("/pos/all");
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const deleteFile = (e, field) => {
    if (field === "Cheque") {
      const s = chequeFile.filter((item, index) => index !== e);
      setChequeFile(s);
      setPersonalDetail({ ...personalDetail, ["bankCopyImageUrl"]: null });
    }
    if (field === "Photo") {
      const s = photoFile.filter((item, index) => index !== e);
      setPhotoFile(s);
      setPersonalDetail({ ...personalDetail, ["photoUrl"]: null });
    }
    if (field === "Aadhaar") {
      const s = aadharFile.filter((item, index) => index !== e);
      setAadharFile(s);
      setPersonalDetail({ ...personalDetail, ["aadhaarImageFront"]: null });
    }
    if (field === "AadhaarBack") {
      const s = aadharFileBack.filter((item, index) => index !== e);
      setAadharFileBack(s);
      setPersonalDetail({ ...personalDetail, ["aadhaarImageBack"]: null });
    }
    if (field === "CNIC") {
      const s = cnicFile.filter((item, index) => index !== e);
      setCnicFile(s);
      setPersonalDetail({ ...personalDetail, ["pancardImageUrl"]: null });
    }

    if (field === "Offers") {
      const s = cnicFile.filter((item, index) => index !== e);
      setCnicFile(s);
      setPersonalDetail({ ...personalDetail, ["eduProofImageUrl1"]: null });
      setPersonalDetail({ ...personalDetail, ["eduProofImageUrl2"]: null });
    }
  };
  useEffect(() => {
    if (result) {
      const tempResult = {};
      tempResult.bankName = result.bankName;
      tempResult.accountHolderName = result.accountHolderName;
      tempResult.accountNumber = result.accountNumber;
      tempResult.ifscCode = result.ifscCode;
      tempResult.bankCopyImageUrl = result.bankCopyImageUrl;
      tempResult.photoUrl = result.photoUrl;
      tempResult.mobileNo = result.mobileNo;
      tempResult.aadhaarNo = result.aadhaarNo;
      tempResult.pancardNo = result.pancardNo;
      tempResult.emailId = result.emailId;
      tempResult.eduProofImageUrl1 = result.eduProofImageUrl1;
      tempResult.eduProofImageUrl2 = result.eduProofImageUrl2;
      tempResult.aadhaarFrontImage = result.aadhaarFrontImage;
      tempResult.branchManager = result.branchManager;
      tempResult.bankcopyName = result.bankcopyName;
      tempResult.assignedBranch = result.assignedBranch;
      tempResult.posId = result.id;
      setPersonalDetail(tempResult);
    }
  }, [result]);
  useEffect(() => {
    dispatch(getPosInfo(id));
    setTimeout(() => {
      setShow(true);
    }, 500);
  }, []);

  return (
    <div className="pos">
      <Header title="View POS" />
      {show ? (
        <div className="p-4 bg-white shadow rounded-3">
          <Accordion defaultActiveKey="0" flush>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Personal Details</Accordion.Header>
              <Accordion.Body>
                <button
                  className="btn btn-primary"
                  onClick={() => editDetails(false)}
                >
                  Edit Personal Details
                </button>
                <PersonalDetailsForm
                  isDisabled={edit}
                  data={result}
                  uploadSingleFile={uploadSingleFile}
                  personalchk={personalchk}
                  personalDetail={personalDetail}
                  photoLoad={photoLoad}
                  deleteFile={deleteFile}
                  photoFile={photoFile}
                  AadhaarLoad={AadhaarLoad}
                  aadharFile={aadharFile}
                  AadhaarLoadBack={AadhaarLoadBack}
                  aadharFileBack={aadharFileBack}
                  panLoad={panLoad}
                  cnicFile={cnicFile}
                />
                {edit == false && (
                  <button className="btn btn-primary" onClick={updatePDetails}>
                    Update Personal Details
                  </button>
                )}  
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Contact Details</Accordion.Header>
              <Accordion.Body>
                <ContactDetailsForm isDisabled={true} data={result} />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>Education Details</Accordion.Header>
              <Accordion.Body>
                <button
                  className="btn btn-primary"
                  onClick={() => editDetails(false)}
                >
                  Edit Education Details
                </button>
                <EducationDetailsFrom
                  isDisabled={edit}
                  data={result}
                  uploadSingleFile={uploadSingleFile}
                  personalchk={personalchk}
                  personalDetail={personalDetail}
                  eduLoad={eduLoad}
                  deleteFile={deleteFile}
                  offersFile={offersFile}
                />
                {edit == false && (
                  <button className="btn btn-primary" onClick={updateEDetails}>
                    Update Education Details
                  </button>
                )}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>Bank Details</Accordion.Header>
              <Accordion.Body>
                <button
                  className="btn btn-primary"
                  onClick={() => editDetails(false)}
                >
                  Edit Bank Details
                </button>
                <BankDetailsForm
                  isDisabled={edit}
                  data={result}
                  uploadSingleFile={uploadSingleFile}
                  personalchk={personalchk}
                  personalDetail={personalDetail}
                  bankLoad={bankLoad}
                  deleteFile={deleteFile}
                  chequeFile={chequeFile}
                />
                {edit == false && (
                  <button className="btn btn-primary" onClick={updateBDetails}>
                    Update Bank Details
                  </button>
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <BranchForm
            isDisabled={
              result?.posStatus !== "Details Submitted" ? true : false
            }
            data={result}
            personalchk={personalchk}
          />
          <POSStatus data={result} personalDetail={personalDetail} />
          <AgreementForm data={result} />
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default ViewPOS;
