import React, { useState, useEffect } from "react";
// import Topbar from "../../../../Dashboard.js";
// import Leftdashboard from "../../../../Leftdashboard.js";
import axios from "axios";
import "./ListMembers.css";
import AddMemberModal from "./AddMember";
import Constant from "../../utils/constant";
export default function ListMembers({}) {
  const [showAddMemberModal, setShowAddMemberModal] = useState(false);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [editModal, setEditModal] = useState(false); // Modal state
  const [editMember, setEditMember] = useState({
    member_id: "",
    member_name: "",
    member_email: "",
    joining_date: "",
    status: "",
  });
  const handleOpenModal = () => setShowAddMemberModal(true);
  const handleCloseModal = () => setShowAddMemberModal(false);

  const fetchData = async () => {
    try {
      const response = await axios.post(
        Constant.BASE_URL + "hrms/listMembers?page=1&limit=10"
      );

      if (
        response.data &&
        response.data.data &&
        Array.isArray(response.data.data)
      ) {
        setData(response.data.data);
        setFilteredData(response.data.data);
      } else {
        setData([]);
        setFilteredData([]);
        setError(response.data.message || "No data found.");
      }
    } catch (err) {
      console.error(err);
      setError("Failed to load data.");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = data.filter((member) => {
      const { member_name, member_email, joining_date } = member.memberData;
      return (
        member_name.toLowerCase().includes(query) ||
        member_email.toLowerCase().includes(query) ||
        joining_date.toLowerCase().includes(query)
      );
    });
    setFilteredData(filtered);
  };

  const renderPermissions = (permissions) => {
    if (!permissions || permissions.length === 0) {
      return "No Permissions";
    }
    return permissions
      .map((perm) => `Permission ${perm.permission_id}`)
      .join(", ");
  };

  const handleDelete = async (memberId) => {
    try {
      const response = await axios.post(
        Constant.BASE_URL + "hrms/deleteMember",
        {
          member_id: memberId,
        }
      );

      if (response.status == 200) {
        console.log("success");
        setData(
          data.filter((member) => member.memberData.member_id !== memberId)
        );
        setFilteredData(
          filteredData.filter(
            (member) => member.memberData.member_id !== memberId
          )
        );
      }
    } catch (err) {
      console.error(err);
      setError("Failed to delete member.");
    }
  };
  const handleEdit = (member) => {
    setEditMember({
      member_id: member.memberData.member_id,
      member_name: member.memberData.member_name,
      member_email: member.memberData.member_email,
      joining_date: member.memberData.joining_date,
      status: member.memberData.status,
    });
    setEditModal(true);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditMember((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSaveEdit = async () => {
    try {
      const response = await axios.post(
        "https://uatapi.policicue.com/hrms/updateMember",
        editMember
      );
      setEditModal(false);

      if (response.status == 200) {
        const updatedResponse = await axios.post(
          "https://uatapi.policicue.com/hrms/listMembers?page=1&limit=10"
        );
        setData(updatedResponse.data.data);
        setFilteredData(updatedResponse.data.data);
      }
    } catch (err) {
      console.error(err);
      setError("Failed to update member.");
    }
  };

  return (
    <div className="ListMembers">
      {/* <Topbar /> */}
      <div className="container-fluid my-2">
        <div className="row">
          {/* <Leftdashboard /> */}

          <main className="mainBodyContainer">
            <div
              className="bg-white shadow p-4 rounded-3"
              style={{ marginBottom: "1.8rem" }}
            >
              <div className="d-flex justify-content-between pb-4  py-0 ">
                <div className="bg-white">
                  <h4
                    className="text-capitalize icueTextPrimary"
                    style={{ fontFamily: "ErasDemiITC" }}
                  >
                    Add Member
                  </h4>
                </div>
                <div className="bg-white">
                  <button
                    type="button"
                    className="btn icueBgPrimary text-white"
                    style={{
                      marginLeft: "15px",
                      width: "250px",
                      textAlign: "center",
                      fontSize: "15px",
                      borderRadius: "4px",
                      paddingBlock: "0.5rem",
                      transition: "none",
                    }}
                    onClick={handleOpenModal}
                  >
                    <i className="bi bi-plus-circle"></i>&nbsp; Add Member
                  </button>
                </div>
              </div>
              {/* // ...

// Search Bar
const handleSearch = (e) => {
  const query = e.target.value.toLowerCase();
  setSearchQuery(query);

  const filtered = data.filter((member) => {
    const { member_name, member_email, joining_date } = member.memberData;
    return (
      member_name.toLowerCase().includes(query) ||
      member_email.toLowerCase().includes(query) ||
      joining_date.toLowerCase().includes(query)
    );
  });
  setFilteredData(filtered);
};

// ...

// Edit Member Modal
const handleEdit = (member) => {
  setEditMember({
    member_id: member.memberData.member_id,
    member_name: member.memberData.member_name,
    member_email: member.memberData.member_email,
    joining_date: member.memberData.joining_date,
    status: member.memberData.status,
  });
  setEditModal(true);
};

const handleEditChange = (e) => {
  const { name, value } = e.target;
  setEditMember((prev) => ({
    ...prev,
    [name]: value,
  }));
};

const handleSaveEdit = async () => {
  try {
    const response = await axios.post(
      Constant.BASE_URL + "hrms/updateMember",
      editMember
    );
    setEditModal(false);

    if (response.status === 200) {
      const updatedResponse = await axios.post(
        Constant.BASE_URL + "hrms/listMembers?page=1&limit=10"
      );
      setData(updatedResponse.data.data);
      setFilteredData(updatedResponse.data.data);
    }
  } catch (err) {
    console.error(err);
    setError("Failed to update member.");
  }
};

// ...

// Render Permissions
const renderPermissions = (permissions) => {
  if (!permissions || permissions.length === 0) {
    return "No Permissions";
  }
  return permissions
    .map((perm) => `Permission ${perm.permission_id}`)
    .join(", ");
};

// ...

// Delete Member
const handleDelete = async (memberId) => {
  try {
    const response = await axios.post(
      Constant.BASE_URL + "hrms/deleteMember",
      {
        member_id: memberId,
      }
    );

    if (response.status === 200) {
      console.log("success");
      setData(
        data.filter((member) => member.memberData.member_id !== memberId)
      );
      setFilteredData(
        filteredData.filter(
          (member) => member.memberData.member_id !== memberId
        )
      );
    }
  } catch (err) {
    console.error(err);
    setError("Failed to delete member.");
  }
};

// ...*/}
              ```
            </div>

            {/* Search Bar */}

            <div className="bg-white p-2 rounded-3 shadow-sm">
              <div class="d-flex justify-content-between">
                <h4
                  class="text-captalize"
                  style={{ color: "#3a3285", padding: "5px" }}
                >
                  List of Members
                </h4>
              </div>
              <div class="icueSearchBox w-100 ">
                <div
                  className="input-group mb-3"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <span className="input-group-text" id="basic-addon1">
                    <i className="bi bi-search"></i>
                  </span>
                  <input
                    type="search"
                    className=" search form-control"
                    placeholder="Search Users by Name, Email "
                    value={searchQuery}
                    onChange={handleSearch}
                    style={{ backgroundColor: "#F4F7FC" }}
                  />

                  <div>
                    {/* Add Member Button */}

                    {/* Add Member Modal */}
                    {showAddMemberModal && (
                      <AddMemberModal
                        show={showAddMemberModal}
                        handleClose={handleCloseModal}
                        refreshData={fetchData}
                      />
                    )}
                  </div>
                </div>
              </div>
              {loading ? (
                <p>Loading members...</p>
              ) : error ? (
                <p className="text-danger">{error}</p>
              ) : (
                <div className="table-responsive">
                  <table className="table table-hover results">
                    <thead>
                      <tr
                        className="text-uppercase"
                        style={{
                          fontFamily: "Calibri",
                          fontStyle: "normal",
                          fontSize: "12px",
                          backgroundColor: "#F4F7FC",
                        }}
                      >
                        <th className="py-3">ID</th>
                        <th className="py-3">NAME</th>
                        <th className="py-3 d-none d-md-table-cell">EMAIL</th>
                        <th className="py-3">ROLE</th>
                        <th className="py-3 d-none d-lg-table-cell">
                          DEPARTMENT
                        </th>
                        <th className="py-3">JOINED ON</th>
                        <th className="py-3 d-none d-md-table-cell">
                          PERMISSIONS
                        </th>
                        <th className="py-3">STATUS</th>
                        <th className="py-3">ACTIONS</th>
                      </tr>
                    </thead>
                    <tbody style={{ fontFamily: "Calibri", fontSize: "14px" }}>
                      {filteredData.map((member, index) => (
                        <tr
                          key={member.memberData.member_id}
                          style={{
                            backgroundColor:
                              index % 2 === 0 ? "#FFFFFF" : "#F4F7FC",
                          }}
                        >
                          <td>{member.memberData.member_id}</td>
                          <td>{member.memberData.member_name}</td>
                          <td className="d-none d-md-table-cell">
                            {member.memberData.member_email}
                          </td>
                          <td>{member.memberData.role_name}</td>
                          <td className="d-none d-lg-table-cell">
                            {member.memberData.department_name}
                          </td>
                          <td>{member.memberData.joining_date}</td>
                          <td className="d-none d-md-table-cell">
                            {renderPermissions(member.memberPermission)}
                          </td>
                          <td>
                            <span
                              className={`badge rounded-pill ${
                                member.memberData.status === "Active"
                                  ? "bg-success"
                                  : "bg-secondary"
                              } py-2 w-75`}
                            >
                              {member.memberData.status}
                            </span>
                          </td>
                          <td>
                            <button
                              type="button"
                              className="btn btn-sm btn-transparent text-muted"
                              onClick={() => handleEdit(member)} // Edit button handler
                            >
                              <i className="bi bi-pencil"></i>
                            </button>
                            <button
                              type="button"
                              className="btn btn-sm btn-transparent"
                              onClick={() =>
                                handleDelete(member.memberData.member_id)
                              } // Delete button handler
                            >
                              <i className="bi bi-trash"></i>
                            </button>
                            <button
                              type="button"
                              className="btn btn-sm btn-transparent"
                            >
                              <i className="bi bi-three-dots"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </main>
        </div>
      </div>
      {editModal && (
        <div className="modal" style={{ display: "block" }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Edit Member</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setEditModal(false)} // Close modal
                ></button>
              </div>
              <div className="modal-body">
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="mb-3">
                    <label htmlFor="member_name" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="member_name"
                      name="member_name"
                      value={editMember.member_name}
                      onChange={handleEditChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="member_email" className="form-label">
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="member_email"
                      name="member_email"
                      value={editMember.member_email}
                      onChange={handleEditChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="joining_date" className="form-label">
                      Joining Date
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      id="joining_date"
                      name="joining_date"
                      value={editMember.joining_date}
                      onChange={handleEditChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="status" className="form-label">
                      Status
                    </label>
                    <select
                      className="form-control"
                      id="status"
                      name="status"
                      value={editMember.status}
                      onChange={handleEditChange}
                    >
                      <option value="Active">Active</option>
                      <option value="Inactive">Inactive</option>
                    </select>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => setEditModal(false)} // Close modal
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={handleSaveEdit} // Save changes
                    >
                      Save Changes
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import "./RequestedLeaves.css";
// // import Topbar from "../../../../Dashboard";
// // import ApprovedLeaves from "./ApprovedLeaves";
// // import Leftdashboard from "../../../../Leftdashboard";

// export default function RequestedLeaves() {
//   const [reqdata, setReqdata] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [searchQuery, setSearchQuery] = useState("");

//   useEffect(() => {
//     // Fetching data from the API
//     const fetchData = async () => {
//       try {
//         const response = await axios.post(
//           "https://uatapi.policicue.com/hrms/requestedLeaves?page=1&limit=10"
//         );
//         setReqdata(response.data.data);
//         setLoading(false);
//       } catch (err) {
//         console.error(err);
//         setError("Failed to load requested leaves.");
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, []);

//   const updateLeaveStatus = async (id, status) => {
//     try {
//       await axios.post("https://uatapi.policicue.com/hrms/updateLeaveStatus", {
//         id,
//         leave_status: status,
//       });
//       setReqdata((prevData) =>
//         prevData.map((item) =>
//           item.id === id ? { ...item, leave_status: status } : item
//         )
//       );
//     } catch (err) {
//       console.error("Error updating leave status:", err);
//       alert("Failed to update leave status.");
//     }
//   };

//   // Filter data based on leave status
//   const approvedLeaves = reqdata.filter(
//     (item) => item.leave_status === "Approved"
//   );
//   const rejectedLeaves = reqdata.filter(
//     (item) => item.leave_status === "Rejected"
//   );

//   // Implement search functionality
//   const filteredData = reqdata.filter((item) =>
//     item.employee_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
//     item.leave_type.toLowerCase().includes(searchQuery.toLowerCase()) ||
//     item.leave_status.toLowerCase().includes(searchQuery.toLowerCase())
//   );

//   return (
//     <div className="requested-leaves-container">
//       {/* Search Bar */}
//       <div className="search-bar">
//         <input
//           type="text"
//           placeholder="Search by employee name, leave type, or status"
//           value={searchQuery}
//           onChange={(e) => setSearchQuery(e.target.value)}
//           className="search-input"
//         />
//       </div>

//       {/* Requested Leaves List */}
//       {loading ? (
//         <p>Loading...</p>
//       ) : error ? (
//         <p>{error}</p>
//       ) : (
//         <div className="leaves-list">
//           <h4>Requested Leaves</h4>
//           <table className="table">
//             <thead>
//               <tr>
//                 <th>Employee Name</th>
//                 <th>Leave Type</th>
//                 <th>Leave Status</th>
//                 <th>Action</th>
//               </tr>
//             </thead>
//             <tbody>
//               {filteredData.length > 0 ? (
//                 filteredData.map((leave) => (
//                   <tr key={leave.id}>
//                     <td>{leave.employee_name}</td>
//                     <td>{leave.leave_type}</td>
//                     <td>{leave.leave_status}</td>
//                     <td>
//                       {leave.leave_status === "Requested" && (
//                         <div>
//                           <button
//                             onClick={() => updateLeaveStatus(leave.id, "Approved")}
//                             className="btn btn-success"
//                           >
//                             Approve
//                           </button>
//                           <button
//                             onClick={() => updateLeaveStatus(leave.id, "Rejected")}
//                             className="btn btn-danger"
//                           >
//                             Reject
//                           </button>
//                         </div>
//                       )}
//                     </td>
//                   </tr>
//                 ))
//               ) : (
//                 <tr>
//                   <td colSpan="4">No matching records found</td>
//                 </tr>
//               )}
//             </tbody>
//           </table>
//         </div>
//       )}
//     </div>
//   );
// }
