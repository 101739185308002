import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import "./Onboard.css";
import { Button, Modal, ListGroup } from "react-bootstrap";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { updateAadhaarImageBack } from "../../store/actions/HrmsOnboard";
import Constant from "../../utils/constant";
import { useLocation } from "react-router-dom";
export default function ViewDetails() {
  const location = useLocation();
  const { employee, workingCompany } = location.state || {};

  const employeeId = employee?.employee_id;

  const [employeeData, setEmployeeData] = useState(employee || null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);
  const [editableSection, setEditableSection] = useState(null);

  const toggleEditMode = (section) => {
    setEditableSection((prevSection) =>
      prevSection === section ? null : section
    );
  };
  const documents = [
    { id: 1, name: "Resume / CV", remarks: "Updated One" },
    { id: 2, name: "Policicue Offer Letter", remarks: "Self - Attested" },
    { id: 3, name: "Passport Size Photo", remarks: "Color Copy (5)" },
    { id: 4, name: "Aadhar Card", remarks: "Address Proof" },
    { id: 5, name: "Pan Card", remarks: "Id Proof" },
    {
      id: 6,
      name: "Academic Certificate",
      remarks: "10th / 12th / Graduate / Post Graduate / Others",
    },
    { id: 7, name: "Cancelled Cheque Copy", remarks: "Bank Account Details" },
    {
      id: 8,
      name: "Previous Company Appointment Letter",
      remarks: "Self - Attested Copy / E-mail from Registered E-mail",
    },
    {
      id: 9,
      name: "Salary Slip Previous Company / Bank Statement",
      remarks: "Last 3 Months",
    },
    {
      id: 10,
      name: "Relieving Letter of all Previous Organizations",
      remarks: "Self - Attested Copy / E-mail from Registered E-mail",
    },
    { id: 11, name: "Reward and Recognition", remarks: "If any" },
  ];
  const [checkedItems, setCheckedItems] = useState(new Array(11).fill(false));
  const [showModal, setShowModal] = useState(false);
  const [woorkingCompany, setWorkingCompany] = useState(
    workingCompany || [
      {
        company_name: "",
        job_title: "",
        start_date: "",
        end_date: "",
        pay_slip: "",
        relieving_letter: "",
        signed_resignation_letter: "",
        offer_letter: "",
      },
    ]
  );
  const handleCheckboxChangee = (index) => {
    const updatedCheckedItems = [...checkedItems];
    updatedCheckedItems[index] = !updatedCheckedItems[index];
    setCheckedItems(updatedCheckedItems);
  };
  const addJobSection = () => {
    setWorkingCompany((prevCompanies) => [
      ...prevCompanies,
      {
        company_name: "",
        job_title: "",
        start_date: "",
        end_date: "",
        pay_slip: "",
        relieving_letter: "",
        signed_resignation_letter: "",
        offer_letter: "",
      },
    ]);
  };

  // const toggleEditMode = () => {
  //   setIsEditMode((prev) => !prev);
  // };

  const handleUpdate = async () => {
    try {
      // Simulating an API call to update the data
      setLoading(true);
      await axios.put(
        `https://uatapi.policicue.com/hrms/updateEmployeeDetails/${employeeId}`,
        employeeData
      );
      alert("Employee details updated successfully!");
      setIsEditMode(false);
    } catch (err) {
      console.error("Error updating employee data:", err);
      alert("Failed to update employee details.");
    } finally {
      setLoading(false);
    }
  };
  const handleInputChange = (e) => {
    if (!e.target) return; // Guard clause for safety
    const { name, value } = e.target;
    setEmployeeData((prev) => ({ ...prev, [name]: value }));
  };

  const removeJobSection = (index) => {
    if (woorkingCompany.length > 1) {
      setWorkingCompany((prevCompanies) =>
        prevCompanies.filter((_, i) => i !== index)
      );
    }
  };
  useEffect(() => {
    if (employeeId && !employee) {
      fetchEmployeeDetails(employeeId);
    }
  }, [employeeId, employee]);

  const fetchEmployeeDetails = async (id) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `https://uatapi.policicue.com/hrms/getEmployeeList?page=1&limit=1`,
        { id }
      );
      if (response.data && response.data.data) {
        setEmployeeData(response.data.data[0]);
      } else {
        setError("Employee not found.");
      }
    } catch (err) {
      console.error("Error fetching employee data:", err);
      setError("Failed to fetch employee data.");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = () => {
    alert("Employee details submitted successfully!");
  };

  if (loading) {
    return <p>Loading employee details...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  if (!employeeData) {
    return <p>No employee selected.</p>;
  }
  return (
    <div className="AgentCustomer">
      {/*<!-- Main Body --> */}

      <div className="container-fluid my-2">
        <div className="row">
          <main className="mainBodyContainer">
            <div className="row my-2">
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-2 px-3">
                <div className="py-3">
                  <h4
                    className="text-uppercase text-center"
                    style={{
                      fontFamily: "ErasDemiITC",
                      fontWeight: "normal",
                    }}
                  >
                    Onboard Employee
                  </h4>
                </div>
              </div>
            </div>
            <div className="row py-0">
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-0 px-3">
                <div className="px-2 rounded-3">
                  <hr className="my-0" />
                </div>
              </div>
            </div>
            <div className="row py-0">
              <div className="multisteps-form">
                <div className="row"></div>

                <div className="row">
                  <div className="col-12 col-lg-12 m-auto">
                    <form className="multisteps-form__form">
                      <div
                        className="multisteps-form__panel rounded js-active"
                        data-animation="scaleIn"
                      >
                        {/* Personal info section */}
                        <div
                          className="bg-white p-4 shadow  pb-5 rounded-3"
                          style={{
                            marginLeft: "0.8rem",
                            marginRight: "0.8rem",
                          }}
                        >
                          <div className="d-flex justify-content-between border-bottom pb-3">
                            <div className="bg-white">
                              <h4
                                className="text-capitalize icueTextPrimary"
                                style={{ fontFamily: "ErasDemiITC" }}
                              >
                                Personal information
                              </h4>
                            </div>
                            <div className="bg-white">
                              <button
                                type="button"
                                className="btn btn-transparent"
                                onClick={() => toggleEditMode("personal")}
                                id="icueEditGenInfoDetails"
                              >
                                {editableSection === "personal" ? (
                                  <>
                                    <i className="bi bi-x-circle" />
                                  </>
                                ) : (
                                  <>
                                    <i className="bi bi-pencil" /> Edit
                                  </>
                                )}
                              </button>
                              {editableSection === "personal" && (
                                <button
                                  type="button"
                                  className="btn btn-primary ms-2"
                                  onClick={handleUpdate}
                                  id="icueSaveGenInfoDetails"
                                >
                                  <i className="bi bi-save" /> Update
                                </button>
                              )}
                            </div>
                          </div>
                          <div
                            className="multisteps-form__content"
                            style={{ fontFamily: "Calibri" }}
                          >
                            <div className="row pt-4">
                              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                <label
                                  htmlFor="icuePersonalFormCueMemTitle"
                                  className="form-label icueTextPrimary icuePersonalInfoLabel"
                                >
                                  Title
                                </label>
                                <select
                                  //   value={employeeData.title}
                                  onChange={handleInputChange}
                                  value={employeeData.title || ""}
                                  readOnly={editableSection !== "educational"}
                                  id="icuePersonalFormCueMemTitle"
                                  name="title"
                                  className={`form-select text-uppercase${
                                    isEditMode ? "" : "bg-white text-muted"
                                  }`}
                                >
                                  <option value="mr.">mr.</option>
                                  <option value="ms.">ms.</option>
                                  <option value="mrs.">mrs.</option>
                                </select>
                              </div>
                              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                <label
                                  htmlFor="icuePersonalFormCueMemFname"
                                  className="form-label icueTextPrimary icuePersonalInfoLabel"
                                >
                                  First Name
                                </label>
                                <input
                                  value={employeeData.first_name || ""}
                                  onChange={handleInputChange}
                                  readOnly={editableSection !== "personal"}
                                  type="text"
                                  id="icuePersonalFormCueMemFname"
                                  name="first_name"
                                  className={`form-control ${
                                    editableSection === "personal"
                                      ? ""
                                      : "bg-white text-muted"
                                  }`}
                                />
                              </div>

                              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                <label
                                  htmlFor="icuePersonalFormCueMemLName"
                                  className="form-label icueTextPrimary icuePersonalInfoLabel"
                                >
                                  Last name
                                </label>
                                <input
                                  type="text"
                                  id="icuePersonalFormCueMemLName"
                                  name="last_name"
                                  className={`form-control ${
                                    editableSection === "personal"
                                      ? ""
                                      : "bg-white text-muted"
                                  }`}
                                  defaultValue="abc"
                                  value={employeeData.last_name || ""}
                                  onChange={handleInputChange}
                                  readOnly={editableSection !== "personal"}
                                />
                              </div>
                              <div>
                                <hr />
                              </div>
                              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                <label
                                  htmlFor="icuePersonalFormCueMemRegAdd"
                                  className="form-label icueTextPrimary icuePersonalInfoLabel"
                                >
                                  City
                                </label>
                                <input
                                  value={employeeData.city || ""}
                                  readOnly={editableSection !== "personal"}
                                  type="text"
                                  id="icuePersonalFormCueMemRegAdd"
                                  name="city"
                                  className={`form-control ${
                                    editableSection === "personal"
                                      ? ""
                                      : "bg-white text-muted"
                                  }`}
                                  defaultValue="abc"
                                  onChange={handleInputChange}
                                />
                              </div>
                              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                <label
                                  htmlFor=""
                                  className="form-label icueTextPrimary icuePersonalInfoLabel"
                                >
                                  State
                                </label>
                                <input
                                  value={employeeData.state || ""}
                                  readOnly={editableSection !== "personal"}
                                  type="text"
                                  id=""
                                  name="state"
                                  className={`form-control ${
                                    editableSection === "personal"
                                      ? ""
                                      : "bg-white text-muted"
                                  }`}
                                  defaultValue="abc"
                                  onChange={handleInputChange}
                                />
                              </div>
                              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                <label
                                  htmlFor="icuePersonalFormCueMemPin"
                                  className="form-label icueTextPrimary icuePersonalInfoLabel"
                                >
                                  Pincode
                                </label>
                                <input
                                  value={employeeData.pincode || ""}
                                  readOnly={editableSection !== "personal"}
                                  type="text"
                                  id="icuePersonalFormCueMemPin"
                                  name="pincode"
                                  className={`form-control ${
                                    editableSection === "personal"
                                      ? ""
                                      : "bg-white text-muted"
                                  }`}
                                  defaultValue={12211}
                                  onChange={handleInputChange}
                                />
                              </div>
                              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                <label
                                  htmlFor=""
                                  className="form-label icueTextPrimary icuePersonalInfoLabel"
                                >
                                  Country
                                </label>
                                <input
                                  value={employeeData.country || ""}
                                  readOnly={editableSection !== "personal"}
                                  type="text"
                                  id=""
                                  name="country"
                                  className={`form-control ${
                                    editableSection === "personal"
                                      ? ""
                                      : "bg-white text-muted"
                                  }`}
                                  defaultValue="abc"
                                  onChange={handleInputChange}
                                />
                              </div>
                              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                <label
                                  htmlFor="icuePersonalFormCueMemEmail"
                                  className="form-label icueTextPrimary icuePersonalInfoLabel"
                                >
                                  E-mail
                                </label>
                                <input
                                  type="email"
                                  value={employeeData.email || ""}
                                  readOnly={editableSection !== "personal"}
                                  id=""
                                  name="email"
                                  className={`form-control ${
                                    editableSection === "personal"
                                      ? ""
                                      : "bg-white text-muted"
                                  }`}
                                  defaultValue="xyz@gmail.com"
                                  onChange={handleInputChange}
                                />
                              </div>
                              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 pt-3">
                                <label
                                  htmlFor="icuePersonalFormCueMemPhoneNo"
                                  className="form-label icueTextPrimary icuePersonalInfoLabel"
                                >
                                  Contact Number
                                </label>
                                <input
                                  value={employeeData.contact_number || ""}
                                  readOnly={editableSection !== "personal"}
                                  type="text"
                                  id="icuePersonalFormCueMemPhoneNo"
                                  name="contact_number"
                                  className={`form-control ${
                                    editableSection === "personal"
                                      ? ""
                                      : "bg-white text-muted"
                                  }`}
                                  defaultValue="xxxxxxxxxx"
                                  onChange={handleInputChange}
                                />
                              </div>
                              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 pt-3">
                                <label
                                  htmlFor="icuePersonalFormCueMemMobNo"
                                  className="form-label icueTextPrimary icuePersonalInfoLabel"
                                >
                                  Emergency Contact Number
                                </label>
                                <input
                                  value={
                                    employeeData.emergency_contact_number || ""
                                  }
                                  readOnly={editableSection !== "personal"}
                                  type="text"
                                  id="icuePersonalFormCueMemMobNo"
                                  name="emergency_contact_number"
                                  className={`form-control ${
                                    editableSection === "personal"
                                      ? ""
                                      : "bg-white text-muted"
                                  }`}
                                  defaultValue="xxxxxxxxxx"
                                  onChange={handleInputChange}
                                />
                              </div>

                              <div>
                                <hr />
                              </div>
                              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                <label
                                  htmlFor="icuePersonalFormCueMemPanNo"
                                  className="form-label icueTextPrimary icuePersonalInfoLabel"
                                >
                                  Date Of Birth
                                </label>
                                <input
                                  value={
                                    employeeData.dob
                                      ? new Date(employeeData.dob)
                                          .toISOString()
                                          .split("T")[0]
                                      : ""
                                  }
                                  readOnly={editableSection !== "personal"}
                                  type="date"
                                  onChange={handleInputChange}
                                  id="icuePersonalFormCueMemPanNo"
                                  name="dob"
                                  className={`form-control ${
                                    editableSection === "personal"
                                      ? ""
                                      : "bg-white text-muted"
                                  }`}
                                  defaultValue="alwpg5809l"
                                />
                              </div>
                              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                <label
                                  htmlFor=""
                                  className="form-label icueTextPrimary icuePersonalInfoLabel"
                                >
                                  Gender
                                </label>
                                <select
                                  value={employeeData.gender || ""}
                                  readOnly={editableSection !== "personal"}
                                  id=""
                                  onChange={handleInputChange}
                                  name="gender"
                                  className={`form-control ${
                                    editableSection === "personal"
                                      ? ""
                                      : "bg-white text-muted"
                                  }`}
                                >
                                  <option value="selft employed">Male</option>
                                  <option value="self">Female</option>
                                  <option value="employed">Other</option>
                                </select>
                              </div>
                              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                <label
                                  htmlFor="icuePersonalFormCueMemProfession"
                                  className="form-label icueTextPrimary icuePersonalInfoLabel"
                                >
                                  Marital Status
                                </label>
                                <select
                                  onChange={handleInputChange}
                                  id="icuePersonalFormCueMemProfession"
                                  name="marital_status"
                                  value={employeeData.marital_status || ""}
                                  readOnly={editableSection !== "personal"}
                                  className={`form-control ${
                                    editableSection === "personal"
                                      ? ""
                                      : "bg-white text-muted"
                                  }`}
                                >
                                  <option value="selft employed">Yes</option>
                                  <option value="self">No</option>
                                </select>
                              </div>

                              <div>
                                <hr />
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Educational Section */}
                        <div
                          className="bg-white shadow p-4 pb-5 rounded-3"
                          style={{
                            marginTop: "4rem",
                            marginLeft: "0.8rem",
                            marginRight: "0.8rem",
                          }}
                        >
                          <div className="d-flex justify-content-between pb-4 border-bottom py-0 mb-3">
                            <div className="bg-white">
                              <h4
                                className="text-capitalize icueTextPrimary"
                                style={{ fontFamily: "ErasDemiITC" }}
                              >
                                Educational Information
                              </h4>
                            </div>
                            <div className="bg-white">
                              <button
                                type="button"
                                className="btn btn-transparent"
                                onClick={() => toggleEditMode("educational")}
                                id="icueEditGenInfoDetails"
                              >
                                {editableSection === "educational" ? (
                                  <>
                                    <i className="bi bi-x-circle" /> Cancel
                                  </>
                                ) : (
                                  <>
                                    <i className="bi bi-pencil" /> Edit
                                  </>
                                )}
                              </button>
                              {editableSection === "educational" && (
                                <button
                                  type="button"
                                  className="btn btn-primary ms-2"
                                  onClick={handleUpdate}
                                  id="icueSaveGenInfoDetails"
                                >
                                  <i className="bi bi-save" /> Update
                                </button>
                              )}
                            </div>
                          </div>
                          <div
                            className="multisteps-form__content"
                            style={{ fontFamily: "Calibri" }}
                          >
                            <div className="row">
                              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                <label
                                  htmlFor="icueGenFormCueMember"
                                  className="form-label icueTextPrimary icueGenInfoLabel mt-3"
                                >
                                  Highest Qualification
                                </label>
                                <select
                                  value={
                                    employeeData.highest_qualification || ""
                                  }
                                  onChange={handleInputChange}
                                  readOnly={editableSection !== "educational"}
                                  id="icueGenFormCueMember"
                                  name="highest_qualification"
                                  // className="form-select  text-uppercase icueGenFormInput   bg-white icueGenFormSel text-muted"
                                  className={`form-control text-uppercase${
                                    editableSection === "educational"
                                      ? ""
                                      : "bg-white text-muted"
                                  }`}
                                >
                                  <option value="HighSchool">
                                    High School
                                  </option>
                                  <option value="Bachelors">Bachelors</option>
                                  <option value="Masters">Masters</option>
                                  <option value="PhD">PhD</option>
                                </select>
                              </div>
                              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                <label
                                  htmlFor="icueGenFormTypeInsurance"
                                  className="form-label icueTextPrimary icueGenInfoLabel mt-3"
                                >
                                  Degree
                                </label>
                                <input
                                  onChange={handleInputChange}
                                  value={employeeData.dgree || ""}
                                  readOnly={editableSection !== "educational"}
                                  type="text"
                                  id="icueGenFormTypeInsurance"
                                  name="dgree"
                                  // className="form-control text-uppercase icueGenFormInput   bg-white text-muted"
                                  className={`form-control text-uppercase${
                                    editableSection === "educational"
                                      ? ""
                                      : "bg-white text-muted"
                                  }`}
                                />
                              </div>
                              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                <label
                                  htmlFor="icueGenFormTypeInsurance"
                                  className="form-label icueTextPrimary icueGenInfoLabel mt-3"
                                >
                                  Institution/University Name
                                </label>
                                <input
                                  onChange={handleInputChange}
                                  value={
                                    employeeData.institute_university_name || ""
                                  }
                                  readOnly={editableSection !== "educational"}
                                  type="text"
                                  id="icueGenFormTypeInsurance"
                                  name="institute_university_name"
                                  // className="form-control text-uppercase icueGenFormInput   bg-white text-muted"
                                  className={`form-control text-uppercase${
                                    editableSection === "educational"
                                      ? ""
                                      : "bg-white text-muted"
                                  }`}
                                  defaultValue=" "
                                />
                              </div>
                              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                <label
                                  htmlFor="icueGenFormTypeInsurance"
                                  className="form-label icueTextPrimary icueGenInfoLabel mt-3"
                                >
                                  Year of Graduation
                                </label>
                                <input
                                  onChange={handleInputChange}
                                  value={employeeData.year_of_graduation || ""}
                                  readOnly={editableSection !== "educational"}
                                  type="text"
                                  id="icueGenFormTypeInsurance"
                                  name="year_of_graduation"
                                  // className="form-control text-uppercase icueGenFormInput   bg-white text-muted"
                                  className={`form-control text-uppercase${
                                    editableSection === "educational"
                                      ? ""
                                      : "bg-white text-muted"
                                  }`}
                                  defaultValue=" "
                                />
                              </div>
                              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                <label
                                  htmlFor="icueGenFormAlreadyInsured"
                                  className="form-label icueTextPrimary icueGenInfoLabel mt-3"
                                >
                                  Board of Education
                                </label>
                                <select
                                  onChange={handleInputChange}
                                  value={employeeData.board_of_education || ""}
                                  readOnly={editableSection !== "educational"}
                                  id="icueGenFormAlreadyInsured"
                                  name="board_of_education"
                                  // className="form-select text-uppercase icueGenFormInput   bg-white icueGenFormSel text-muted"
                                  className={`form-control text-uppercase${
                                    editableSection === "educational"
                                      ? ""
                                      : "bg-white text-muted"
                                  }`}
                                >
                                  <option value="no">HBSE</option>
                                  <option value="yes">CBSE</option>
                                  <option value="yes">ICSE</option>
                                </select>
                              </div>
                              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                <label
                                  htmlFor="icueGenFormTypeInsurance"
                                  className="form-label icueTextPrimary icueGenInfoLabel mt-3"
                                >
                                  Class 10 Percentage
                                </label>
                                <input
                                  onChange={handleInputChange}
                                  value={employeeData.calss_10_percentage || ""}
                                  readOnly={editableSection !== "educational"}
                                  type="text"
                                  id="icueGenFormTypeInsurance"
                                  name="calss_10_percentage"
                                  // className="form-control text-uppercase icueGenFormInput   bg-white text-muted"
                                  className={`form-control icueGenFormInput   text-uppercase  ${
                                    isEditMode ? "" : "bg-white text-muted"
                                  }`}
                                  defaultValue="%"
                                />
                              </div>
                              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                <label
                                  htmlFor="icueGenFormTypeInsurance"
                                  className="form-label icueTextPrimary icueGenInfoLabel mt-3"
                                >
                                  Class 12 Percentage
                                </label>
                                <input
                                  onChange={handleInputChange}
                                  value={employeeData.class_12_percentage || ""}
                                  readOnly={editableSection !== "educational"}
                                  type="text"
                                  id="icueGenFormTypeInsurance"
                                  name="class_12_percentage"
                                  // className="form-control text-uppercase icueGenFormInput   bg-white text-muted"
                                  className={`form-control text-uppercase${
                                    editableSection === "educational"
                                      ? ""
                                      : "bg-white text-muted"
                                  }`}
                                  defaultValue="%"
                                />
                              </div>
                              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                <label
                                  htmlFor="icueGenFormAlreadyInsured"
                                  className="form-label icueTextPrimary icueGenInfoLabel mt-3"
                                >
                                  Class 12 Stream
                                </label>
                                <select
                                  value={employeeData.class_12_stream || ""}
                                  readOnly={editableSection !== "educational"}
                                  id="icueGenFormAlreadyInsured"
                                  name="class_12_stream"
                                  // className="form-select text-uppercase icueGenFormInput   bg-white icueGenFormSel text-muted"
                                  className={`form-control text-uppercase${
                                    editableSection === "educational"
                                      ? ""
                                      : "bg-white text-muted"
                                  }`}
                                >
                                  <option value="Commerce">Commerce</option>
                                  <option value="Science">Science</option>
                                  <option value="Arts">Arts</option>
                                </select>
                              </div>
                              <br />

                              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                <label
                                  htmlFor="icueGenFormTypeInsurance"
                                  className="form-label icueTextPrimary icueGenInfoLabel mt-3"
                                >
                                  Upload Class 10 Marksheet
                                </label>
                                <input
                                  onChange={handleInputChange}
                                  // value={employeeData.class_10_marksheet || ""}
                                  readOnly={editableSection !== "educational"}
                                  type="file"
                                  id="icueGenFormTypeInsurance"
                                  name="class_10_marksheet"
                                  // className="form-control text-uppercase icueGenFormInput   bg-white text-muted"
                                  className={`form-control text-uppercase${
                                    editableSection === "educational"
                                      ? ""
                                      : "bg-white text-muted"
                                  }`}
                                  defaultValue=""
                                />
                              </div>
                              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                <label
                                  htmlFor="icueGenFormTypeInsurance"
                                  className="form-label icueTextPrimary icueGenInfoLabel mt-3"
                                >
                                  Upload Class 12 Marksheet
                                </label>
                                <input
                                  onChange={handleInputChange}
                                  // value={employeeData.class_12_marksheet || ""}
                                  readOnly={editableSection !== "educational"}
                                  type="file"
                                  id="icueGenFormTypeInsurance"
                                  name="class_12_marksheet"
                                  // className="form-control text-uppercase icueGenFormInput   bg-white text-muted"
                                  className={`form-control text-uppercase${
                                    editableSection === "educational"
                                      ? ""
                                      : "bg-white text-muted"
                                  }`}
                                />
                              </div>
                              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                <label
                                  htmlFor="icueGenFormTypeInsurance"
                                  className="form-label icueTextPrimary icueGenInfoLabel mt-3"
                                >
                                  Upload Highest Qualification Marksheet
                                </label>
                                <input
                                  onChange={handleInputChange}
                                  // value={
                                  //   employeeData.highest_qualification_marksheet ||
                                  //   ""
                                  // }
                                  readOnly={editableSection !== "educational"}
                                  type="file"
                                  id="icueGenFormTypeInsurance"
                                  name="highest_qualification_marksheet"
                                  // className="form-control text-uppercase icueGenFormInput   bg-white text-muted"
                                  className={`form-control text-uppercase${
                                    editableSection === "educational"
                                      ? ""
                                      : "bg-white text-muted"
                                  }`}
                                  defaultValue=""
                                />
                              </div>
                              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                <label
                                  htmlFor="icueGenFormTypeInsurance"
                                  className="form-label icueTextPrimary icueGenInfoLabel mt-3"
                                >
                                  Skills Acquired
                                </label>
                                <input
                                  onChange={handleInputChange}
                                  value={employeeData.skill_acquired || ""}
                                  readOnly={editableSection !== "educational"}
                                  type="text"
                                  id="icueGenFormTypeInsurance"
                                  name="skill_acquired"
                                  // className="form-control text-uppercase icueGenFormInput   bg-white text-muted"
                                  className={`form-control text-uppercase${
                                    editableSection === "educational"
                                      ? ""
                                      : "bg-white text-muted"
                                  }`}
                                  defaultValue=" "
                                />
                              </div>
                              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                <label
                                  htmlFor="icueGenFormTypeInsurance"
                                  className="form-label icueTextPrimary icueGenInfoLabel mt-3"
                                >
                                  Certification
                                </label>
                                <input
                                  onChange={handleInputChange}
                                  // value={
                                  //   employeeData.certification_skill_acquired ||
                                  //   ""
                                  // }
                                  readOnly={editableSection !== "educational"}
                                  // value={
                                  //   employeeData.certification_skill_acquired
                                  // }

                                  type="file"
                                  id="icueGenFormTypeInsurance"
                                  name="certification_skill_acquired"
                                  // className="form-control text-uppercase icueGenFormInput   bg-white text-muted"
                                  className={`form-control text-uppercase${
                                    editableSection === "educational"
                                      ? ""
                                      : "bg-white text-muted"
                                  }`}
                                />
                              </div>
                              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                <label
                                  htmlFor="icueGenFormTypeInsurance"
                                  className="form-label icueTextPrimary icueGenInfoLabel mt-3"
                                >
                                  Additional Courses
                                </label>
                                <input
                                  onChange={handleInputChange}
                                  value={employeeData.additional_courses || ""}
                                  readOnly={editableSection !== "educational"}
                                  type="text"
                                  id="icueGenFormTypeInsurance"
                                  name="additional_courses"
                                  // className="form-control text-uppercase icueGenFormInput   bg-white text-muted"
                                  className={`form-control text-uppercase${
                                    editableSection === "educational"
                                      ? ""
                                      : "bg-white text-muted"
                                  }`}
                                  defaultValue=" "
                                />
                              </div>
                              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                <label
                                  htmlFor="icueGenFormTypeInsurance"
                                  className="form-label icueTextPrimary icueGenInfoLabel mt-3"
                                >
                                  Certification
                                </label>
                                <input
                                  onChange={handleInputChange}
                                  // value={
                                  //   employeeData.certification_additional_courses ||
                                  //   ""
                                  // }
                                  readOnly={editableSection !== "educational"}
                                  // value={
                                  //   employeeData.certification_additional_courses
                                  // }

                                  type="file"
                                  id="icueGenFormTypeInsurance"
                                  name="certification_additional_courses"
                                  // className="form-control text-uppercase icueGenFormInput   bg-white text-muted"
                                  className={`form-control text-uppercase${
                                    editableSection === "educational"
                                      ? ""
                                      : "bg-white text-muted"
                                  }`}
                                />
                              </div>
                            </div>
                            <hr></hr>{" "}
                          </div>
                        </div>
                        {/*  Previous Employment Details */}
                        <div
                          className="bg-white shadow p-4 pb-5 rounded-3"
                          style={{
                            marginTop: "4rem",
                            marginLeft: "0.8rem",
                            marginRight: "0.8rem",
                          }}
                        >
                          <div className="d-flex justify-content-between pb-4 border-bottom py-0 mb-3">
                            <div className="bg-white">
                              <h4
                                className="text-capitalize icueTextPrimary"
                                style={{ fontFamily: "ErasDemiITC" }}
                              >
                                Previous Employment Details
                              </h4>
                            </div>
                            <div className="bg-white">
                              <button
                                type="button"
                                className="btn btn-transparent"
                                onClick={() => toggleEditMode("PreEmployment")}
                                id="icueEditGennInfoDetails"
                              >
                                {editableSection === "PreEmployment" ? (
                                  <>
                                    <i className="bi bi-x-circle" /> Cancel
                                  </>
                                ) : (
                                  <>
                                    <i className="bi bi-pencil" /> Edit
                                  </>
                                )}
                              </button>
                              {editableSection === "PreEmployment" && (
                                <button
                                  type="button"
                                  className="btn btn-primary ms-2"
                                  onClick={handleUpdate}
                                  id="icueSaveGennInfoDetails"
                                >
                                  <i className="bi bi-save" /> Update
                                </button>
                              )}
                            </div>
                          </div>

                          <div
                            className="multisteps-form__content"
                            style={{ fontFamily: "Calibri" }}
                          >
                            {/* <div className="bg-white">
                              <FontAwesomeIcon
                                icon={faTrash}
                                type="button"
                                className="btn btn-transparent icueTextInfoLight"
                                id="icueEditGennInfoDetails"
                                onClick={() => removeJobSection(index)}
                                style={{
                                  float: "right",
                                  color: "red",
                                  cursor: "pointer",
                                  fontSize: "20px",
                                }}
                              />
                            </div> */}
                            <div className="row">
                              {/* {workingCompany && workingCompany.length > 0 ? ( */}
                              {woorkingCompany.map((company, index) => (
                                <div className="row" key={index}>
                                  <div className="bg-white">
                                    <FontAwesomeIcon
                                      icon={faTrash}
                                      type="button"
                                      className="btn btn-transparent icueTextInfoLight"
                                      id="icueEditGennInfoDetails"
                                      onClick={() => removeJobSection(index)}
                                      style={{
                                        float: "right",
                                        color: "red",
                                        cursor: "pointer",
                                        fontSize: "20px",
                                      }}
                                    />
                                  </div>
                                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                    <label
                                      htmlFor={`companyName`}
                                      className="form-label icueTextPrimary icueGennInfoLabel mt-3"
                                    >
                                      Company Name
                                    </label>
                                    <input
                                      value={company.company_name || ""}
                                      readOnly={
                                        editableSection !== "PreEmployment"
                                      }
                                      type="text"
                                      onChange={handleInputChange}
                                      id={`companyName-`}
                                      name="company_name"
                                      // className="form-control text-uppercase icueGennFormInput   bg-white text-muted"
                                      className={`form-control ${
                                        editableSection === "PreEmployment"
                                          ? ""
                                          : "bg-white text-muted"
                                      }`}
                                    />
                                  </div>

                                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                    <label
                                      htmlFor={`jobTitle-`}
                                      className="form-label icueTextPrimary icueGennInfoLabel mt-3"
                                    >
                                      Job Title
                                    </label>
                                    <input
                                      value={company.job_title || ""}
                                      readOnly={
                                        editableSection !==
                                        "educaPreEmploymenttional"
                                      }
                                      onChange={handleInputChange}
                                      type="text"
                                      id={`jobTitle-`}
                                      name="job_title"
                                      // className="form-control text-uppercase icueGennFormInput   bg-white text-muted"
                                      className={`form-control ${
                                        editableSection === "PreEmployment"
                                          ? ""
                                          : "bg-white text-muted"
                                      }`}
                                    />
                                  </div>
                                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                    <label
                                      htmlFor={`startDate-${company.id}`}
                                      className="form-label icueTextPrimary icueGennInfoLabel mt-3"
                                    >
                                      Start Date
                                    </label>
                                    <input
                                      value={
                                        company.start_date
                                          ? new Date(company.start_date)
                                              .toISOString()
                                              .split("T")[0]
                                          : ""
                                      }
                                      readOnly={
                                        editableSection !== "PreEmployment"
                                      }
                                      type="date"
                                      onChange={handleInputChange}
                                      id={`startDate-${company.id}`}
                                      name="start_date"
                                      // className="form-control text-uppercase icueGennFormInput bg-white text-muted "
                                      className={`form-control ${
                                        editableSection === "PreEmployment"
                                          ? ""
                                          : "bg-white text-muted"
                                      }`}
                                    />
                                  </div>

                                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                    <label
                                      htmlFor={`endDate- `}
                                      className="form-label icueTextPrimary icueGennInfoLabel mt-3"
                                    >
                                      End Date
                                    </label>
                                    <input
                                      value={
                                        company.end_date
                                          ? new Date(company.end_date)
                                              .toISOString()
                                              .split("T")[0]
                                          : ""
                                      }
                                      readOnly={
                                        editableSection !== "PreEmployment"
                                      }
                                      type="date"
                                      onChange={handleInputChange}
                                      id={`endDate- `}
                                      name="end_date"
                                      // className="form-control text-uppercase icueGennFormInput   bg-white text-muted"
                                      className={`form-control ${
                                        editableSection === "PreEmployment"
                                          ? ""
                                          : "bg-white text-muted"
                                      }`}
                                    />
                                  </div>
                                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                    <label
                                      htmlFor={`payslip- `}
                                      className="form-label icueTextPrimary icueGennInfoLabel mt-3"
                                    >
                                      Upload Payslip
                                    </label>
                                    <input
                                      type="file"
                                      readOnly={
                                        editableSection !== "PreEmployment"
                                      }
                                      id={`payslip- `}
                                      onChange={handleInputChange}
                                      name="payslip"
                                      // className="form-control text-uppercase icueGennFormInput   bg-white text-muted"
                                      className={`form-control ${
                                        editableSection === "PreEmployment"
                                          ? ""
                                          : "bg-white text-muted"
                                      }`}
                                    />
                                  </div>
                                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                    <label
                                      htmlFor={`relievingLetter- `}
                                      className="form-label icueTextPrimary icueGennInfoLabel mt-3"
                                    >
                                      Upload Relieving Letter
                                    </label>
                                    <input
                                      type="file"
                                      readOnly={
                                        editableSection !== "PreEmployment"
                                      }
                                      onChange={handleInputChange}
                                      id={`relievingLetter- `}
                                      name="relievingLetter"
                                      // className="form-control text-uppercase icueGennFormInput   bg-white text-muted"
                                      className={`form-control ${
                                        editableSection === "PreEmployment"
                                          ? ""
                                          : "bg-white text-muted"
                                      }`}
                                    />
                                  </div>
                                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                    <label
                                      htmlFor={`resignationLetter- `}
                                      className="form-label icueTextPrimary icueGennInfoLabel mt-3"
                                    >
                                      Upload Signed Resignation Letter
                                    </label>
                                    <input
                                      readOnly={
                                        editableSection !== "PreEmployment"
                                      }
                                      type="file"
                                      onChange={handleInputChange}
                                      id={`resignationLetter- `}
                                      name="resignationLetter"
                                      // className="form-control text-uppercase icueGennFormInput   bg-white text-muted"
                                      className={`form-control ${
                                        editableSection === "PreEmployment"
                                          ? ""
                                          : "bg-white text-muted"
                                      }`}
                                    />
                                  </div>
                                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                    <label
                                      htmlFor={`offerLetter- `}
                                      className="form-label icueTextPrimary icueGennInfoLabel mt-3"
                                    >
                                      Upload Offer Letter
                                    </label>
                                    <input
                                      type="file"
                                      readOnly={
                                        editableSection !== "PreEmployment"
                                      }
                                      onChange={handleInputChange}
                                      id={`offerLetter- `}
                                      name="offerLetter"
                                      // className="form-control text-uppercase icueGennFormInput   bg-white text-muted"
                                      className={`form-control ${
                                        editableSection === "PreEmployment"
                                          ? ""
                                          : "bg-white text-muted"
                                      }`}
                                    />
                                  </div>
                                </div>
                              ))}
                            </div>

                            <hr />
                          </div>

                          {/* Add More Company Button */}
                          <button
                            type="button"
                            onClick={addJobSection}
                            style={{
                              marginTop: "20px",
                              marginLeft: "20px",
                              padding: "10px 20px",
                              backgroundColor: "#007bff",
                              color: "#fff",
                              border: "none",
                              borderRadius: "5px",
                              cursor: "pointer",
                            }}
                          >
                            Add More Company
                          </button>
                        </div>
                        {/* Employment Details */}
                        <div
                          className="bg-white shadow p-4 pb-5 rounded-3"
                          style={{
                            marginTop: "4rem",
                            marginLeft: "0.8rem",
                            marginRight: "0.8rem",
                          }}
                        >
                          <div className="d-flex justify-content-between pb-4 border-bottom py-0 mb-3">
                            <div className="bg-white">
                              <h4
                                className="text-capitalize icueTextPrimary"
                                style={{ fontFamily: "ErasDemiITC" }}
                              >
                                Employment Details
                              </h4>
                            </div>
                            <div className="bg-white">
                              <button
                                type="button"
                                className="btn btn-transparent"
                                onClick={() => toggleEditMode("Employment")}
                                id="icueEditEmpInfoDetails"
                              >
                                {editableSection === "Employment" ? (
                                  <>
                                    <i className="bi bi-x-circle" /> Cancel
                                  </>
                                ) : (
                                  <>
                                    <i className="bi bi-pencil" /> Edit
                                  </>
                                )}
                              </button>
                              {editableSection === "Employment" && (
                                <button
                                  type="button"
                                  className="btn btn-primary ms-2"
                                  onClick={handleUpdate}
                                  id="icueSaveGEmpInfoDetails"
                                >
                                  <i className="bi bi-save" /> Update
                                </button>
                              )}
                            </div>
                          </div>
                          <div
                            className="multisteps-form__content"
                            style={{ fontFamily: "Calibri" }}
                          >
                            <div className="row">
                              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                <label
                                  htmlFor="icueEmpFormTypeInsured"
                                  className="form-label icueTextPrimary icueEmpInfoLabel mt-3"
                                >
                                  Employee ID
                                </label>
                                <input
                                  value={employeeData.employee_id || ""}
                                  readOnly={editableSection !== "Employment"}
                                  type="text"
                                  onChange={handleInputChange}
                                  id="icueEmpFormTypeInsured"
                                  name="employee_id"
                                  // className="form-control text-uppercase icueEmpFormInput   bg-white text-muted"
                                  className={`form-control ${
                                    editableSection === "Employment"
                                      ? ""
                                      : "bg-white text-muted"
                                  }`}
                                  defaultValue="55555"
                                />
                              </div>
                              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                <label
                                  htmlFor="icueEmpFormTypeInsurance"
                                  className="form-label icueTextPrimary icueEmpInfoLabel mt-3"
                                >
                                  Position
                                </label>
                                <input
                                  type="text"
                                  value={employeeData.joining_position || ""}
                                  readOnly={editableSection !== "Employment"}
                                  onChange={handleInputChange}
                                  id="icueEmpFormTypeInsurance"
                                  name="joining_position"
                                  // className="form-control text-uppercase icueEmpFormInput   bg-white text-muted"
                                  className={`form-control ${
                                    editableSection === "Employment"
                                      ? ""
                                      : "bg-white text-muted"
                                  }`}
                                  defaultValue="Manager"
                                />
                              </div>
                              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                <label
                                  htmlFor="icueEmpFormTypeInsurance"
                                  className="form-label icueTextPrimary icueEmpInfoLabel mt-3"
                                >
                                  Department
                                </label>
                                <input
                                  type="text"
                                  value={employeeData.department || ""}
                                  readOnly={editableSection !== "Employment"}
                                  onChange={handleInputChange}
                                  id="icueEmpFormTypeInsurance"
                                  name="department"
                                  // className="form-control text-uppercase icueEmpFormInput   bg-white text-muted"
                                  className={`form-control ${
                                    editableSection === "Employment"
                                      ? ""
                                      : "bg-white text-muted"
                                  }`}
                                  defaultValue="IT"
                                />
                              </div>
                              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                <label
                                  htmlFor="icueEmpFormTypeInsurance"
                                  className="form-label icueTextPrimary icueEmpInfoLabel mt-3"
                                >
                                  Reporting Manager
                                </label>
                                <input
                                  value={employeeData.reporting_manager || ""}
                                  readOnly={editableSection !== "Employment"}
                                  type="text"
                                  onChange={handleInputChange}
                                  id="icueEmpFormTypeInsurance"
                                  name="reporting_manager"
                                  // className="form-control text-uppercase icueEmpFormInput   bg-white text-muted"
                                  className={`form-control ${
                                    editableSection === "Employment"
                                      ? ""
                                      : "bg-white text-muted"
                                  }`}
                                  defaultValue=" abc"
                                />
                              </div>

                              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                <label
                                  htmlFor="icueEmpFormTypeInsurance"
                                  className="form-label icueTextPrimary icueEmpInfoLabel mt-3"
                                >
                                  Joining Date
                                </label>
                                <input
                                  onChange={handleInputChange}
                                  value={
                                    employeeData.joining_date
                                      ? new Date(employeeData.joining_date)
                                          .toISOString()
                                          .split("T")[0]
                                      : ""
                                  }
                                  readOnly={editableSection !== "Employment"}
                                  type="date"
                                  id="icueEmpFormTypeInsurance"
                                  name="joining_date"
                                  className={`form-control ${
                                    editableSection === "Employment"
                                      ? ""
                                      : "bg-white text-muted"
                                  }`}
                                  // className="form-control text-uppercase icueEmpFormInput   bg-white text-muted"
                                  //
                                  defaultValue=""
                                />
                              </div>
                              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                <label
                                  htmlFor="icueEmpFormTypeInsurance"
                                  className="form-label icueTextPrimary icueEmpInfoLabel mt-3"
                                >
                                  Work Location
                                </label>
                                <input
                                  value={employeeData.work_location || ""}
                                  readOnly={editableSection !== "Employment"}
                                  type="text"
                                  onChange={handleInputChange}
                                  id="icueEmpFormTypeInsurance"
                                  name="work_location"
                                  // className="form-control text-uppercase icueEmpFormInput   bg-white text-muted"
                                  className={`form-control ${
                                    editableSection === "Employment"
                                      ? ""
                                      : "bg-white text-muted"
                                  }`}
                                  defaultValue="INdia"
                                />
                              </div>
                              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                <label
                                  htmlFor="icueEmpFormAlreadyInsured"
                                  className="form-label icueTextPrimary icueEmpInfoLabel mt-3"
                                >
                                  Employment Type
                                </label>
                                <select
                                  value={employeeData.employee_type || ""}
                                  readOnly={editableSection !== "Employment"}
                                  onChange={handleInputChange}
                                  id="icueEmpFormAlreadyInsured"
                                  name="employee_type"
                                  // className="form-select text-uppercase icueEmpFormInput   bg-white icueEmpFormSel text-muted"
                                  className={`form-control ${
                                    editableSection === "Employment"
                                      ? ""
                                      : "bg-white text-muted"
                                  }`}
                                >
                                  <option value="FullTimeEmployee">
                                    Full Time Employee
                                  </option>
                                  <option value="Trainee">Trainee</option>
                                  <option value="Internship">Internship</option>
                                </select>
                              </div>
                              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                <label
                                  htmlFor="icueEmpFormTypeInsurance"
                                  className="form-label icueTextPrimary icueEmpInfoLabel mt-3"
                                >
                                  Shift Time
                                </label>
                                <select
                                  value={employeeData.shift_timing || ""}
                                  readOnly={editableSection !== "Employment"}
                                  onChange={handleInputChange}
                                  id="icueEmpFormAlreadyInsured"
                                  name="shift_timing"
                                  className={`form-control ${
                                    editableSection === "Employment"
                                      ? ""
                                      : "bg-white text-muted"
                                  }`}
                                  // className="form-select text-uppercase icueEmpFormInput   bg-white icueEmpFormSel text-muted"
                                >
                                  <option value="morning">Morning</option>
                                  <option value="evening">Evening</option>
                                  <option value="night">Night</option>
                                </select>
                              </div>
                            </div>
                            <hr></hr>
                          </div>
                        </div>
                        {/* Document Verification */}
                        <div
                          className="bg-white shadow p-4 pb-5 rounded-3"
                          style={{
                            marginTop: "4rem",
                            marginLeft: "0.8rem",
                            marginRight: "0.8rem",
                          }}
                        >
                          <div className="d-flex justify-content-between pb-4 border-bottom py-0 mb-3">
                            <div className="bg-white">
                              <h4
                                className="text-capitalize icueTextPrimary"
                                style={{ fontFamily: "ErasDemiITC" }}
                              >
                                Document Verification
                              </h4>
                            </div>
                            <div className="bg-white">
                              <button
                                type="button"
                                className="btn btn-transparent"
                                onClick={() => toggleEditMode("Document")}
                                id="icueEditDocInfoDetails"
                              >
                                {editableSection === "Document" ? (
                                  <>
                                    <i className="bi bi-x-circle" /> Cancel
                                  </>
                                ) : (
                                  <>
                                    <i className="bi bi-pencil" /> Edit
                                  </>
                                )}
                              </button>
                              {editableSection === "Document" && (
                                <button
                                  type="button"
                                  className="btn btn-primary ms-2"
                                  onClick={handleUpdate}
                                  id="icueSaveDocInfoDetails"
                                >
                                  <i className="bi bi-save" /> Update
                                </button>
                              )}
                            </div>
                          </div>
                          <div
                            className="multisteps-form__content"
                            style={{ fontFamily: "Calibri" }}
                          >
                            <div className="row">
                              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                <label
                                  htmlFor="icueDocFormTypeInsurance"
                                  className="form-label icueTextPrimary icueDocInfoLabel mt-3"
                                >
                                  Upload Aadhar Card
                                </label>
                                <input
                                  // value={employeeData.adhar}
                                  onChange={handleInputChange}
                                  type="file"
                                  readOnly={editableSection !== "Document"}
                                  id="icueDocFormTypeInsurance"
                                  name="adhar"
                                  className={`form-control ${
                                    editableSection === "Document"
                                      ? ""
                                      : "bg-white text-muted"
                                  }`}
                                  required
                                  defaultValue=""
                                />
                              </div>
                              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                <label
                                  htmlFor="icueDocFormTypeInsurance"
                                  className="form-label icueTextPrimary icueDocInfoLabel mt-3"
                                >
                                  Upload PAN Card
                                </label>
                                <input
                                  // value={employeeData.pan}
                                  onChange={handleInputChange}
                                  readOnly={editableSection !== "Document"}
                                  type="file"
                                  id="icueDocFormTypeInsurance"
                                  name="pan"
                                  className={`form-control ${
                                    editableSection === "Document"
                                      ? ""
                                      : "bg-white text-muted"
                                  }`}
                                  required
                                  defaultValue=""
                                />
                              </div>
                              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                <label
                                  htmlFor="icueDocFormTypeInsurance"
                                  className="form-label icueTextPrimary icueDocInfoLabel mt-3"
                                >
                                  Upload Passport
                                </label>
                                <input
                                  // value={employeeData.passport}
                                  readOnly={editableSection !== "Document"}
                                  onChange={handleInputChange}
                                  type="file"
                                  id="icueDocFormTypeInsurance"
                                  name="passport"
                                  className={`form-control ${
                                    editableSection === "Document"
                                      ? ""
                                      : "bg-white text-muted"
                                  }`}
                                  defaultValue=""
                                />
                                {/* <div>
                                  <input
                                    type="file"
                                    onChange={handleFileChange}
                                    name="passport"
                                  />
                                  {loading && <p>Uploading...</p>}
                                  {data && (
                                    <p>Upload successful: {data.message}</p>
                                  )}
                                  {error && <p>Error: {error}</p>}
                                </div> */}
                              </div>
                            </div>
                            <hr></hr>
                          </div>
                          <button
                            className="signup-btn"
                            style={{
                              backgroundColor: "#007bff",
                              width: "270px",
                              height: "50px",
                              border: "none",
                              borderRadius: "25px",
                              alignItems: "center",
                              color: "#fff",
                              fontWeight: "normal",
                              margin: " 20px 0",
                              fontFamily: "ERASDEMIITC",
                              fontSize: "1rem",
                            }}
                            type="submit"
                            onClick={handleSubmit}
                          >
                            Submit Details
                          </button>
                          {/* <button
                            className="signup-btn"
                            style={{
                              backgroundColor: "#007bff",
                              width: "270px",
                              height: "50px",
                              border: "none",
                              borderRadius: "25px",
                              alignItems: "center",
                              marginLeft: "10px",
                              color: "#fff",
                              fontWeight: "normal",
                              margin: " 20px 0",
                              fontFamily: "ERASDEMIITC",

                              fontSize: "1rem",
                            }}
                            type=""
                            onClick={() => setShowModal(true)}
                          >
                            Edit Checklists
                          </button> */}
                          <Modal
                            show={showModal}
                            onHide={() => setShowModal(false)}
                            centered
                          >
                            <Modal.Header closeButton>
                              <Modal.Title>
                                Employee Joining Kit Checklist
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <p>
                                Kindly share your documents within 2 days from
                                your joining date to process your joining with
                                Policicue. Failing to submit the above-mentioned
                                documents may null and void Policicue offer.
                              </p>
                              <ListGroup>
                                {documents.map((doc, index) => (
                                  <ListGroup.Item
                                    key={doc.id}
                                    className="checklist-item"
                                  >
                                    <label>
                                      <input
                                        type="checkbox"
                                        checked={checkedItems[index]}
                                        onChange={() =>
                                          handleCheckboxChangee(index)
                                        }
                                      />
                                      {doc.name}
                                    </label>
                                    <span className="checklist-description">
                                      {" "}
                                      - {doc.remarks}
                                    </span>
                                  </ListGroup.Item>
                                ))}
                              </ListGroup>
                            </Modal.Body>
                            <Modal.Footer>
                              <Button
                                variant="secondary"
                                onClick={() => setShowModal(false)}
                              >
                                Close
                              </Button>
                            </Modal.Footer>
                          </Modal>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}

// import { useLocation } from "react-router-dom";
// import { useState, useEffect } from "react";
// import axios from "axios";

// export default function ViewDetails() {
//   const location = useLocation();
//   const { employee } = location.state || {};

//   const [employeeData, setEmployeeData] = useState(null);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState("");

//   useEffect(() => {
//     if (employeeId) {
//       fetchEmployeeDetails(employeeId);
//     }
//   }, [employeeId]);

//   const fetchEmployeeDetails = async (id) => {
//     setLoading(true);
//     try {
//       const response = await axios.post(
//         `https://uatapi.policicue.com/hrms/getEmployeeList?page=1&limit=1`,
//         { id } // Include ID in the payload or query if required by the API
//       );
//       if (response.data && response.data.data) {
//         setEmployeeData(response.data.data[0]); // Assuming API returns an array
//       } else {
//         setError("Employee not found.");
//       }
//     } catch (err) {
//       console.error("Error fetching employee data:", err);
//       setError("Failed to fetch employee data.");
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleSubmit = () => {
//     alert("Employee details submitted successfully!");
//   };

//   if (loading) {
//     return <p>Loading employee details...</p>;
//   }

//   if (error) {
//     return <p>{error}</p>;
//   }

//   if (!employeeData) {
//     return <p>No employee selected.</p>;
//   }

//   return (
//     <div className="AgentCustomer">
//       <div className="container-fluid my-2">
//         <h4 className="text-uppercase text-center">Onboard Employee</h4>
//         <div className="row">
//           <div className="col-xxl-4">
//             <label className="form-label">First Name</label>
//             <input
//               type="text"
//               className="form-control"
//               value={employeeData.first_name || ""}
//               readOnly={editableSection !== "educational"}
//             />
//           </div>
//           <div className="col-xxl-4">
//             <label className="form-label">Last Name</label>
//             <input
//               type="text"
//               className="form-control"
//               value={employeeData.last_name || ""}
//               readOnly={editableSection !== "educational"}
//             />
//           </div>
//           <div className="col-xxl-4">
//             <label className="form-label">City</label>
//             <input
//               type="text"
//               className="form-control"
//               value={employeeData.city || ""}
//               readOnly={editableSection !== "educational"}
//             />
//           </div>
//         </div>
//         <button
//           className="signup-btn"
//           style={{
//             backgroundColor: "#007bff",
//             width: "270px",
//             height: "50px",
//             border: "none",
//             borderRadius: "25px",
//             color: "#fff",
//             fontWeight: "normal",
//             margin: "20px 0",
//           }}
//           type="button"
//           onClick={handleSubmitm
//         >
//           Add Employee
//         </button>
//       </div>
//     </div>
//   );
// }
