import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { getPosInfo } from "../store/actions/posInfo";
import { format_date } from "../utils/methods";
import { Link } from "react-router-dom";

const colorCode = [
  {
    text: "Approved",
    class: "bg-primary text-white",
  },
  {
    text: "Rejected",
    class: "bg-danger text-white",
  },
  {
    text: "Details Submitted",
    class: "bg-dark text-white",
  },
  {
    text: "Pass",
    class: "bg-success text-white",
  },
  {
    text: "Fail",
    class: "bg-danger text-white",
  },
  {
    text: "Agreement Rejected",
    class: "bg-danger text-white",
  },
  {
    text: "Agreement Approved",
    class: "bg-success text-white",
  },
  {
    text: "Agreement Uploaded",
    class: "bg-info text-white",
  },
  {
    text: "Training Completed",
    class: "bg-warning ",
  },
];

const TableHead = ({ posRole }) => {
  return (
    <thead style={{ backgroundColor: "#edf5fb" }}>
      <tr className="text-uppercase" style={{ fontSize: "13px" }}>
        <th scope="col">POSP NAME</th>
        <th scope="col">POSP CODE</th>
        <th scope="col">MOBILE NO.</th>
        <th scope="col">APPLICATION DATE</th>
        <th scope="col">POS STATUS</th>
        {posRole?.view_action === 1 && <th scope="col">ACTION</th>}
      </tr>
    </thead>
  );
};

function getClassName(text) {
  var classs = colorCode.find((c) => {
    return c.text === text && c.class;
  });
  return `badge rounded-pill text-dark ${classs && classs.class}`;
}

const TableBody = ({ data, posRole }) => {
  return (
    data && (
      <tbody>
        {data.map((d, i) => (
          <tr key={Date.now() + i}>
            <td style={{ fontFamily: "sans-serif", fontSize: "0.9rem" }}>
              {d?.firstname ? d.firstname : "-"}
            </td>
            <td style={{ fontFamily: "sans-serif", fontSize: "0.9rem" }}>
              {d?.pospCode ? d.pospCode : "-"}
            </td>
            <td style={{ fontFamily: "sans-serif", fontSize: "0.9rem" }}>
              {d?.mobileNo ? d.mobileNo : "-"}
            </td>
            <td style={{ fontFamily: "sans-serif", fontSize: "0.9rem" }}>
              {d?.createdAt ? format_date(d.createdAt) : "-"}
            </td>
            <td>
              {d?.posStatus ? (
                <span className={getClassName(d.posStatus)}>{d.posStatus}</span>
              ) : (
                "-"
              )}
            </td>
            {posRole?.view_action === 1 && (
              <td className="text-center">
                <Link to={`/pos/${d?.id}/view`} onClick={getPosInfo}>
                  <i
                    className="bi bi-eye-fill"
                    style={{ color: "#0a65ff" }}
                  ></i>
                </Link>
              </td>
            )}
          </tr>
        ))}
      </tbody>
    )
  );
};

const NoDataTableBody = () => {
  return (
    <tbody>
      <tr>
        <td className="text-center" colSpan={6}>
          No Data Found.
        </td>
      </tr>
    </tbody>
  );
};

const ShowEntries = ({ totalPage, total, setIPP, ipp }) => {
  const temp = [];
  for (let i = 1; i <= totalPage; i++) {
    i === totalPage ? temp.push(total) : temp.push(10 * i);
  }
  return (
    <div className="d-flex  col-lg-3 align-items-center mb-3 p-0">
      <span>Show</span>
      <select
        className="form-select custom-form-select ms-2 me-2"
        value={ipp}
        onChange={(e) => setIPP(e.target.value)}
      >
        {temp.map((d, i) => (
          <option value={d} key={Date.now() + i} defaultValue={ipp === d}>
            {d}
          </option>
        ))}
      </select>
      <span>Entries</span>
    </div>
  );
};

const POSDetailsTable = ({
  data = null,
  total = 0,
  itemsPerPage = 0,
  setItemsPerPage = void 0,
  paginationHandlePageClick = void 0,
  paginationPageCount = 0,
  posRole,
}) => {
  const [ipp, setIPP] = useState(10);
  const totalPage = Math.ceil(total / 10);
  useEffect(() => {
    setItemsPerPage(ipp);
  }, [ipp, itemsPerPage]);
  return (
    <>
      <ShowEntries
        totalPage={totalPage}
        total={total}
        setIPP={setIPP}
        ipp={ipp}
      />
      <div className="table-responsive">
        <table className="table table-bordered table-striped" width="auto">
          <TableHead posRole={posRole} />
          {data ? (
            <TableBody data={data} posRole={posRole} />
          ) : (
            <NoDataTableBody />
          )}
        </table>
      </div>
      <div className="table_paginate">
        <ReactPaginate
          containerClassName="pagination"
          nextClassName="page-item"
          pageClassName="page-item"
          nextLabel="Next"
          onPageChange={paginationHandlePageClick}
          pageRangeDisplayed={1}
          marginPagesDisplayed={1}
          pageCount={paginationPageCount}
          previousLabel="Previous"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          activeClassName="active"
          renderOnZeroPageCount={null}
        />
      </div>
    </>
  );
};

export default POSDetailsTable;
