import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import "./Roles.css";
import { nanoid } from "nanoid";
import ReadOnlyRow from "./ReadOnlyRow";
import EditableRow from "./EditableRow";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Constant from "../../utils/constant";
const AddRoles = () => {
  const [contacts, setContacts] = useState([]);
  const [addFormData, setAddFormData] = useState({ role: "" });
  const [editFormData, setEditFormData] = useState({ role: "" });
  const [editContactId, setEditContactId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const itemsPerPage = 10;

  const fetchRolesList = async (page = 1) => {
    try {
      const response = await axios.post(
        Constant.BASE_URL + `hrms/listRoles?page=${page}&limit=${itemsPerPage}`
      );
      if (response.data && response.data.data) {
        setContacts(response.data.data);
        const totalItems =
          response.data.total || itemsPerPage * response.data.totalPages;
        setTotalPages(totalItems ? Math.ceil(totalItems / itemsPerPage) : 1);
      }
    } catch (error) {
      console.error("Error fetching roles:", error);
    }
  };

  useEffect(() => {
    fetchRolesList(currentPage);
  }, [currentPage]);

  const handleAddFormChange = (event) => {
    const { name, value } = event.target;
    setAddFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleEditFormChange = (event) => {
    const { name, value } = event.target;
    setEditFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleAddFormSubmit = async (event) => {
    event.preventDefault();

    if (!addFormData.role.trim()) {
      toast.error("Please fill in the role details before adding.");
      return;
    }

    const roleExists = contacts.some(
      (contact) =>
        contact.role_name.toLowerCase() === addFormData.role.toLowerCase()
    );

    if (roleExists) {
      toast.error("This role already exists.");
      return;
    }

    try {
      const newRole = {
        role_name: addFormData.role,
      };

      await axios.post(Constant.BASE_URL + "hrms/addRoles", newRole);

      toast.success("Role added successfully!");

      setAddFormData({ role: "" });
      fetchRolesList(currentPage);
    } catch (error) {
      console.error("Error adding role:", error);
      toast.error("Failed to add role. Please try again.");
    }
  };

  const handleEditFormSubmit = async (event) => {
    event.preventDefault();

    try {
      const updatedRole = {
        role_id: editContactId,
        role_name: editFormData.role,
      };

      await axios.post(Constant.BASE_URL + "hrms/updateRoles", updatedRole);

      setEditContactId(null);
      setEditFormData({ role: "" });

      fetchRolesList(currentPage);
    } catch (error) {
      console.error("Error updating role:", error);
    }
  };

  const handleEditClick = (event, contact) => {
    event.preventDefault();
    setEditContactId(contact.role_id);
    setEditFormData({ role: contact.role_name });
  };

  const handleCancelClick = () => {
    setEditContactId(null);
  };

  const handleDeleteClick = async (roleId) => {
    try {
      await axios.post(Constant.BASE_URL + `hrms/deleteRoles`, {
        role_id: roleId,
      });
      fetchRolesList(currentPage);
    } catch (error) {
      console.error("Error deleting role:", error);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  return (
    <div className="AddRoles">
      {/* <Topbar /> */}
      <div className="container-fluid my-2">
        <div className="row">
          {/* <Leftdashboard /> */}
          <main className="mainBodyContainer">
            <div className="row my-2">
              <div className="col-12 py-2 px-3">
                <h4 className="section-title">Add Roles </h4>
              </div>
            </div>

            <div className="row my-2">
              <div className="col-12 py-1 px-3">
                <div className="bg-white px-5 rounded-3 shadow-sm">
                  <div className="row">
                    <div
                      className="col-lg-4 col-md-6 col-12 addroles"
                      style={{ backgroundColor: "#F4F7FC" }}
                    >
                      <label className="form-label icueTextPrimary role-label">
                        Enter Role Name
                      </label>
                      <form onSubmit={handleAddFormSubmit}>
                        <input
                          type="text"
                          name="role"
                          required
                          className="form-control bg-white text-capitalize role-input"
                          onChange={handleAddFormChange}
                          value={addFormData.role}
                        />
                      </form>
                      <button
                        className="btn-sm text-white add-role-btn"
                        onClick={handleAddFormSubmit}
                      >
                        <i className="bi bi-plus-circle"></i> Add Role
                      </button>
                    </div>

                    <div className="col-lg-8 col-md-6 col-12 tab-content py-1 px-3">
                      <form onSubmit={handleEditFormSubmit}>
                        <table className="table table-striped table-hover">
                          <thead>
                            <tr
                              className="table-header"
                              style={{ backgroundColor: "white" }}
                            >
                              <th className="">Role</th>
                              <th className="text-center actions-header">
                                Actions
                              </th>
                            </tr>
                          </thead>
                          <tbody className="table-body">
                            {contacts.map((contact) => (
                              <Fragment key={contact.role_id}>
                                {editContactId === contact.role_id ? (
                                  <EditableRow
                                    editFormData={editFormData}
                                    handleEditFormChange={handleEditFormChange}
                                    handleCancelClick={handleCancelClick}
                                  />
                                ) : (
                                  <ReadOnlyRow
                                    contact={contact}
                                    handleEditClick={handleEditClick}
                                    handleDeleteClick={handleDeleteClick}
                                  />
                                )}
                              </Fragment>
                            ))}
                          </tbody>
                        </table>
                      </form>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <div className="pagination">
                          <span
                            onClick={handlePreviousPage}
                            disabled={currentPage === 1}
                            style={{
                              cursor: "pointer",
                              cursor:
                                currentPage > 1 ? "pointer" : "not-allowed",
                            }}
                          >
                            &lt;
                          </span>{" "}
                          <span>
                            {" "}
                            Page {currentPage} of{totalPages}
                          </span>
                          <span
                            onClick={handleNextPage}
                            style={{
                              cursor:
                                currentPage < totalPages
                                  ? "pointer"
                                  : "not-allowed",
                            }}
                            disabled={currentPage === totalPages}
                          >
                            &gt;
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default AddRoles;

// import React, { useState, useEffect } from "react";
// // import Topbar from "../../../../Dashboard.js";
// // import Leftdashboard from "../../../../Leftdashboard.js";
// import axios from "axios";
// import "../UserManagement/ListMembers.css";

// import Constant from "../../utils/constant";
// export default function ListMembers({}) {
//   return (
//     <div className="ListMembers">
//       {/* <Topbar /> */}
//       <div className="container-fluid my-2">
//         <div className="row">
//           {/* <Leftdashboard /> */}

//           <main className="mainBodyContainer">
//             <div
//               className="bg-white shadow p-4 rounded-3"
//               style={{ marginBottom: "1.8rem" }}
//             ></div>

//             {/* Search Bar */}

//             <div className="bg-white p-2 rounded-3 shadow-sm">
//               <div class="d-flex justify-content-between">
//                 <h4
//                   class="text-captalize"
//                   style={{ padding: "5px", size: "24px" }}
//                 >
//                   Access Control
//                 </h4>
//               </div>

//               <div className="table-responsive">
//                 <table className="table table-hover results">
//                   <thead>
//                     <tr
//                       className="text-uppercase"
//                       style={{
//                         fontStyle: "normal",
//                         fontSize: "12px",
//                       }}
//                     >
//                       <th
//                         className="py-3"
//                         style={{ backgroundColor: "#E5E7EB" }}
//                       >
//                         Role Name
//                       </th>
//                       <th
//                         className="py-3"
//                         style={{ backgroundColor: "#E5E7EB" }}
//                       >
//                         Last Edited
//                       </th>

//                       <th
//                         className="py-3"
//                         style={{ backgroundColor: "#E5E7EB" }}
//                       >
//                         ACTION
//                       </th>
//                     </tr>
//                   </thead>
//                   <tbody style={{ fontFamily: "Calibri", fontSize: "14px" }}>
//                     <tr
//                       style={{
//                         height: "70px",
//                         alignItems: "center",
//                       }}
//                     >
//                       <td>hello</td>
//                       <td>hello</td>

//                       <td>hello</td>
//                     </tr>
//                     <tr
//                       style={{
//                         height: "70px",
//                         alignItems: "center",
//                       }}
//                     >
//                       <td>hello</td>
//                       <td>hello</td>

//                       <td>hello</td>
//                     </tr>
//                     <tr
//                       style={{
//                         height: "70px",
//                         alignItems: "center",
//                       }}
//                     >
//                       <td>hello</td>
//                       <td>hello</td>

//                       <td>hello</td>
//                     </tr>
//                   </tbody>
//                 </table>
//               </div>
//             </div>
//           </main>
//         </div>
//       </div>
//     </div>
//   );
// }
