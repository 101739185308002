import axios from "axios";
import Constant from "../utils/constant";

const Register = (firstName, lastName, mobileNo, emailId) => {
  return axios
    .post(Constant.BASE_URL + "pos/register", {
      firstName,
      lastName,
      mobileNo,
      emailId,
    })
    .then((response) => {
      if (response.data.data) {
        localStorage.setItem("user", JSON.stringify(response.data.data));
        return response;
      } else {
        alert(response.data.message);
      }
      return response;
    })
    .catch((e) => {});
};

const otp = (phone, email, otp) => {
  return axios
    .patch(Constant.BASE_URL + "pos/verifyOtp", {
      mobileNo: phone,
      emailId: email,
      otp: otp,
    })
    .then((response) => {
      if (response.data.data) {
        alert(response.data.message);
        return response.data.status;
      } else {
        alert(response.data.message);
        return response.data.status;
      }
    })
    .catch((e) => {
      console.error(e);
    });
};

const resendOtp = async (firstName, phone, email) => {
  try {
    const response = await axios.patch(Constant.BASE_URL + "pos/resendOtp", {
      firstName: firstName,
      mobileNo: phone,
      emailId: email,
    });
    if (response.data.data) {
      alert(response.data.message);
    } else {
      alert(response.data.message);
    }
    return response.data.data;
  } catch (e) {
    console.error(e);
  }
};

const login = async (username, password) => {
  const response = await axios.post(Constant.BASE_URL + "admin/login", {
    emailId: username,
    password,
  });
  if (response.data.data) {
    localStorage.setItem("user", JSON.stringify(response.data.data));
  } else {
    alert(response.data.message);
  }
  return response.data.data;
};

const SuperAdminLogin = async (email, password, role) => {
  const response = await axios.post(Constant.BASE_URL + "admin/login", {
    email,
    password,
    role,
  });
  if (response.data.data) {
    localStorage.setItem("superUser", JSON.stringify(response.data.data));
    return response.data.data;
  } else {
    alert(response.data.message);
  }
  return response.data.data;
};

const logout = () => {
  localStorage.removeItem("user");
};

export default {
  Register,
  login,
  otp,
  resendOtp,
  logout,
  SuperAdminLogin,
};
