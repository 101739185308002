import React, { useState, useEffect } from "react";

import axios from "axios";
import "./HRMSDashboard.css";
import { useNavigate } from "react-router-dom";
import Constant from "../utils/constant";
export default function HRDashboard() {
  const [employees, setEmployees] = useState([]);
  const [leaves, setLeaves] = useState([]);
  const [members, setMembers] = useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [loadingEmployees, setLoadingEmployees] = useState(true);
  const [loadingLeaves, setLoadingLeaves] = useState(true);
  const [loadingMembers, setLoadingMembers] = useState(true);
  const [errorEmployees, setErrorEmployees] = useState(null);
  const [errorLeaves, setErrorLeaves] = useState(null);
  const [errorMembers, setErrorMembers] = useState(null);
  const history = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");

  // Fetch the initial list of employees
  const fetchEmployees = async (page = 1) => {
    try {
      setLoadingEmployees(true);
      const response = await axios.post(
        Constant.BASE_URL + `hrms/getEmployeeList?page=${page}&limit=10`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data && response.data.data) {
        const formattedEmployees = response.data.data.map((emp) => ({
          empCode: emp.employeeData.employee_id,
          name: `${emp.employeeData.first_name} ${emp.employeeData.last_name}`,
          email: emp.employeeData.email,
          joinedOn: new Date(
            emp.employeeData.joining_date
          ).toLocaleDateString(),
          designation: emp.employeeData.joining_position,
          status: "Active",
        }));

        setEmployees(formattedEmployees);
        setFilteredEmployees(formattedEmployees);
        const totalRecords = response.data.total;
        setTotalPages(Math.ceil(totalRecords / 10));
      } else {
        setErrorEmployees("No employee data found.");
      }
    } catch (err) {
      console.error("Error fetching employees:", err);
      setErrorEmployees("Failed to load employee data.");
    } finally {
      setLoadingEmployees(false);
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
      fetchEmployees(newPage);
    }
  };

  // Fetch the initial list of requested leaves
  const fetchLeaves = async () => {
    try {
      setLoadingLeaves(true);
      const response = await axios.post(
        Constant.BASE_URL + "hrms/requestedLeaves?page=1&limit=6"
      );
      setLeaves(response.data.data || []);
    } catch (err) {
      setErrorLeaves("Failed to load requested leaves.");
    } finally {
      setLoadingLeaves(false);
    }
  };

  // Fetch the initial list of members
  const fetchMembers = async () => {
    try {
      setLoadingMembers(true);
      const response = await axios.post(
        Constant.BASE_URL + "hrms/listMembers?page=1&limit=10"
      );
      if (response.data && response.data.data) {
        setMembers(response.data.data);
      } else {
        setErrorMembers("No member data found.");
      }
    } catch (err) {
      console.error("Error fetching members:", err);
      setErrorMembers("Failed to load member data.");
    } finally {
      setLoadingMembers(false);
    }
  };
  // useEffect(() => {
  //   if (searchQuery.trim() === "") {
  //     setFilteredEmployees(employees);
  //   } else {
  //     const lowerCaseQuery = searchQuery.toLowerCase();
  //     const filtered = employees.filter(
  //       (employee) =>
  //         employee.name.toLowerCase().includes(lowerCaseQuery.toLowerCase()) ||
  //         employee.email.toLowerCase().includes(lowerCaseQuery)
  //     );
  //     console.log(`filtered List---------- ${filtered}`);
  //     setFilteredEmployees(filtered);
  //   }
  // }, [searchQuery, employees]);

  useEffect(() => {
    if (!searchQuery.trim()) {
      setFilteredEmployees(employees);
    } else {
      const lowerCaseQuery = searchQuery.toLowerCase();

      const filtered = employees.filter((employee) => {
        const name = employee?.name?.toLowerCase() || "";
        const email = employee?.email?.toLowerCase() || "";

        return name.includes(lowerCaseQuery) || email.includes(lowerCaseQuery);
      });

      console.log("Filtered List:", filtered);

      setFilteredEmployees(filtered);
      console.log("Filtered Listhdbsfj:", filteredEmployees);
    }
  }, [searchQuery, employees]);

  useEffect(() => {
    const fetchAllData = async () => {
      await fetchEmployees();
      await fetchLeaves();
      await fetchMembers();
    };

    fetchAllData();
  }, []);

  const handleViewAll = () => {
    history("/hrms/Leave-management/Requested-Leaves");
  };
  const handleAdd = () => {
    history("/hrms/onboarding");
  };

  return (
    <div className="Hrdashboard">
      {/* <!-- Top Bar --> */}

      {/* <Topbar /> */}
      {/* <!-- /Top Bar -->

    <!-- Main Body --> */}
      <div class=" my-2">
        <div class="row">
          {/* <Leftdashboard
            // onEmployeeAdded={handleEmployeeAdded}
            employees={employees}
          /> */}
          <main class="mainBodyContainer">
            <div class="row my-2">
              <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-2 px-3">
                <div class="bg-transparent p-2  rounded-3">
                  <nav
                    style={{
                      bsbreadcrumbdivider:
                        'url("data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="8" height="8"%3E%3Cpath d="M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z" fill="currentColor"/%3E%3C/svg%3E")',
                    }}
                    aria-label="breadcrumb"
                  >
                    <ol class="breadcrumb mb-0 justify-content-end bg-transparent">
                      <li class="breadcrumb-item bg-transparent">
                        <a
                          href="#/hrms/dashboard"
                          class="text-decoration-none "
                          style={{ fontFamily: "Calibri", color: "black" }}
                        >
                          Dashboard
                        </a>
                      </li>
                      {/* <li class="breadcrumb-item text-muted" aria-current="page">LMS</li> */}
                    </ol>
                  </nav>
                </div>
              </div>
            </div>

            <div class="row my-2">
              <div class="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 py-2 px-3">
                <div
                  class="bg-white  shadow icueBorBottomPrimary"
                  style={{ marginLeft: "0.8rem" }}
                >
                  <div class="row py-1">
                    <div class="col-7">
                      <div class="ps-2">
                        <h2
                          class="fs-1 pt-1 icueTextPrimary"
                          style={{
                            fontFamily: "ErasDemiITC",
                            fontSize: "30px",
                          }}
                        >
                          {loadingEmployees ? "..." : employees.length}
                        </h2>
                        <br />
                        <h4
                          class="fs-5 mb-0 text-capitalize icueTextLight"
                          style={{ fontFamily: "ErasDemiITC" }}
                        >
                          total employees
                        </h4>
                      </div>
                    </div>
                    <div class="col-5 text-end">
                      <div class="pe-2">
                        <div class="icueBgLightGrey icueTextPrimary rounded-circle fs-1 d-inline-block p-3 text-center ">
                          <i class="fas fa-users "></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 py-2 px-3">
                <div class="bg-white  shadow icueBorBottomLightInfo">
                  <div class="row py-1">
                    <div class="col-7">
                      <div class="ps-2">
                        <h2
                          class="fs-1 pt-1 icueTextInfoLight"
                          style={{
                            fontFamily: "ErasDemiITC",
                            fontSize: "30px",
                          }}
                        >
                          {loadingMembers ? "..." : members.length}
                        </h2>{" "}
                        <br />
                        <h4
                          class="fs-5 mb-0 text-capitalize icueTextLight"
                          style={{ fontFamily: "ErasDemiITC" }}
                        >
                          Total Members
                        </h4>
                      </div>
                    </div>
                    <div class="col-5 text-end">
                      <div class="pe-2">
                        <div class="icueBgLightGrey icueTextInfoLight rounded-circle fs-1 d-inline-block p-3 text-center ">
                          <i class="fas fa-users "></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 py-2 px-3">
                <div class="bg-white shadow icueBorBottomLightDanger">
                  <div class="row py-1">
                    <div class="col-7">
                      <div class="ps-2">
                        <h2
                          class="fs-1 pt-1 icueTextLightDanger"
                          style={{
                            fontFamily: "ErasDemiITC",
                            fontSize: "30px",
                          }}
                        >
                          15
                        </h2>{" "}
                        <br />
                        <h4
                          class="fs-5 mb-0 text-capitalize icueTextLight"
                          style={{ fontFamily: "ErasDemiITC" }}
                        >
                          unplanned leaves
                        </h4>
                      </div>
                    </div>
                    <div class="col-5 text-end">
                      <div class="pe-2">
                        <div class="icueBgLightGrey icueTextLightDanger rounded-circle fs-1 d-inline-block p-3 text-center ">
                          <i class="fas fa-users "></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row my-2">
              <div className="col-xxl-8 col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12 py-2 px-2">
                <div
                  className="bg-white p-2  rounded-3 shadow-sm"
                  style={{ marginLeft: "0.8rem", marginRight: "0.8rem" }}
                >
                  <div className="px-1 py-3">
                    <div class="container1">
                      <div className="row">
                        <div className="col-xs-12 bg-white">
                          <div className="d-flex align-items-center justify-content-between px-3">
                            {/* Search Box */}
                            <div className="icueSearchBox w-100">
                              <div className="input-group">
                                <span
                                  className="input-group-text"
                                  id="basic-addon1"
                                >
                                  <i className="bi bi-search"></i>
                                </span>
                                <input
                                  type="search"
                                  value={searchQuery}
                                  className="search form-control icueSearchBoxControl"
                                  placeholder="Search Users by Name, Email or Date"
                                  onChange={(e) =>
                                    setSearchQuery(e.target.value)
                                  }
                                  style={{ backgroundColor: "#F4F7FC" }}
                                />
                              </div>
                            </div>

                            {/* Add Employee Button
                             */}
                            <a
                              href="#/hrms/Onboarding/add-employee"
                              type="button"
                              onClick={handleAdd}
                              className="icueBgPrimary text-white text-center ms-2 "
                              style={{
                                width: "160px",
                                fontSize: "15px",
                                textDecoration: "none",
                                padding: "10px 0",
                                borderRadius: "5px",
                              }}
                            >
                              <i className="bi bi-plus-circle"></i> &nbsp;Add
                              Employee
                            </a>
                          </div>
                        </div>
                      </div>{" "}
                      <br />
                      {loadingEmployees ? (
                        <p>Loading employees...</p>
                      ) : errorEmployees ? (
                        <p className="text-danger">{errorEmployees}</p>
                      ) : (
                        <div className="table-responsive">
                          <table
                            id="icueHrTable"
                            className="table results"
                            style={{
                              width: "100%",
                              border: "0",
                              // marginLeft: "-12px",
                            }}
                          >
                            <thead>
                              <tr
                                className="text-uppercase"
                                style={{
                                  lineHeight: "15px",
                                  fontFamily: "Calibri",
                                  // fontSize: "12px",
                                  backgroundColor: "#F4F7FC",
                                }}
                              >
                                <th>Emp code</th>
                                <th>name</th>
                                <th className="email-column">Email id</th>
                                <th className="joined-on-column">Joined on</th>
                                <th>Designation </th>
                                <th className="status-column">Status</th>
                              </tr>
                            </thead>
                            <tbody
                              style={{
                                lineHeight: "15px",
                                fontFamily: "Calibri",
                                // fontSize: "12px",
                              }}
                            >
                              {filteredEmployees.map((employee, index) => (
                                <tr
                                  key={employee.id}
                                  style={{
                                    backgroundColor:
                                      index % 2 === 0 ? "#FFFFFF" : "#F4F7FC",
                                  }}
                                >
                                  <td>{employee.empCode}</td>
                                  <td>{employee.name}</td>
                                  <td className="email-column wrap text-lowercase">
                                    {employee.email}
                                  </td>
                                  <td className="joined-on-column">
                                    {employee.joinedOn}
                                  </td>
                                  <td className="text-center">
                                    {employee.designation}
                                  </td>
                                  <td className="status-column text-center">
                                    <span
                                      className="badge rounded-pill  text-uppercase px-3 text-center py-2 w-100"
                                      style={{ backgroundColor: "#3a3285" }}
                                    >
                                      {employee.status}
                                    </span>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <div className="pagination-controls">
                              <span
                                onClick={() =>
                                  handlePageChange(currentPage - 1)
                                }
                                disabled={currentPage === 1}
                                // className="btn btn-secondary"
                                style={{ cursor: "pointer" }}
                              >
                                &lt;
                              </span>
                              <span className="mx-3">
                                Page {currentPage} of {totalPages}
                              </span>
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  handlePageChange(currentPage + 1)
                                }
                                disabled={currentPage === totalPages}
                                // className="btn btn-secondary"
                              >
                                &gt;
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 py-2 px-3">
                <div
                  className="bg-white pt-4 pb-2 px-4 rounded-3 shadow-sm border-bottom"
                  style={{ marginRight: "0.8rem" }}
                >
                  {" "}
                  <br />
                  <div className="d-flex justify-content-between">
                    <h4
                      className="icueTextPrimary pb-2"
                      style={{
                        fontFamily: "ErasDemiITC",
                        fontSize: "28px",
                        fontWeight: "15px",
                      }}
                    >
                      Requested Leaves
                    </h4>
                    <div>
                      <a
                        href="/Followup"
                        type="text"
                        className=""
                        style={{
                          fontSize: "28px",
                          fontFamily: "ErasDemiITC",
                        }}
                      >
                        {leaves.length || 0}
                      </a>
                    </div>
                  </div>
                  <br />
                  <br />
                  {loadingLeaves ? (
                    <p>Loading...</p>
                  ) : errorLeaves ? (
                    <p className="text-danger">{errorLeaves}</p>
                  ) : (
                    leaves.slice(0, 5).map((leave, index) => (
                      <a href="#/hrms/Leave-management/Requested-Leaves">
                        {" "}
                        <button
                          key={index}
                          type="button"
                          className="btn  text-start text-black w-100 mb-2  "
                          style={{
                            backgroundColor: "#EFF7FD",
                            boxShadow: " 0px 0px 5px rgba(0, 0, 0, 0.2)",
                          }}
                        >
                          <div
                            className="text-uppercase "
                            style={{
                              fontFamily: "Calibri",
                              fontSize: "20px",
                              fontSize: "bold",
                              color: "black",
                              // lineHeight: "0px",
                            }}
                          >
                            {leave.employee_name}
                            <i
                              class="bi bi-check-circle fa-lg"
                              style={{
                                marginLeft: "6px",
                                float: "right",
                                padding: "10px",
                                cursor: "initial",
                                color:
                                  leave.leave_status === "Approved"
                                    ? "#29CC97"
                                    : "#D3D3D3",
                                fontSize: "bold",
                              }}
                            ></i>
                            <i
                              class="bi bi-x-circle fa-lg"
                              style={{
                                // marginLeft: "250px",
                                float: "right",
                                padding: "10px",
                                cursor: "initial",

                                color:
                                  leave.leave_status === "Rejected"
                                    ? "#D64343"
                                    : "#D3D3D3",
                                fontSize: "bold",
                              }}
                            ></i>
                          </div>
                          <small>
                            {" "}
                            <small
                              className="text-capitalize"
                              style={{ color: "#242424", fontSize: "12px" }}
                            >
                              {new Date(leave.leave_from).toLocaleDateString()}
                            </small>{" "}
                          </small>
                        </button>{" "}
                      </a>
                    ))
                  )}
                  <button
                    type="button"
                    class="btn btn-outline-primary  btn-small"
                    style={{
                      // marginLeft: "180px",
                      alignContent: "middle",
                      width: "40%",
                      marginTop: "14px",
                      color: "#3a3285",
                    }}
                    onClick={handleViewAll}
                  >
                    View All{" "}
                    <i
                      class="bi bi-chevron-right"
                      style={{ marginLeft: "10px", marginTop: "20px" }}
                    ></i>
                  </button>
                </div>
              </div>
            </div>

            {/* <!-- Assign Lead To Modal --> */}

            <div class="row my-2">
              <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-2 px-3">
                <div class="bg-white p-2 rounded-3 shadow-sm"></div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}
